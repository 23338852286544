import {ASSETS_API} from "../global";

const getApartments = () => {
  return ASSETS_API.get(`Apartment/V2/GetApartments?buildingQueryParameters.isSearchwithpagination=false`);
};

const getApartment = ApartmentId => {
  return ASSETS_API.get(`Apartment/V2/${ApartmentId}/GetApartmentInformation/`);
};

const updateApartment = async (ApartmentId, data) => {
    return await ASSETS_API.put(`Apartment/V2/EditApartment/${ApartmentId}`, data);
};

const removeApartment = ApartmentId => {
  return ASSETS_API.delete(`Apartment/V2/DeleteApartment/${ApartmentId}`);
};

const findByAddress = (Address,Start,Length) => {
  return ASSETS_API.get(`Apartment/V2/GetApartments?buildingQueryParameters.address=${Address}&buildingQueryParameters.length=${Length}&buildingQueryParameters.start=${Start}&buildingQueryParameters.isSearchwithpagination=true`);
};

const getStructures = () => {
  return ASSETS_API.get(`Apartment/V2/GetStructures`);
};

export default {
  getApartments,
  getApartment,
  updateApartment,
  removeApartment,
  findByAddress,
  getStructures
};
