import {
    CREATE_BOS,
    GET_BOS,
    UPDATE_BOS,
    DELETE_BOS,
  } from "./types";
  
  import buildingObjectStructureService from "../../services/buildingObjectStructureService";
  
  export const createBOS = (Name, NumOfRooms, SelectedRoomsIds) => async (dispatch) => {
    try {
      const res = await buildingObjectStructureService.createBuildingObjectStructure({ Name, NumOfRooms, SelectedRoomsIds });
  
      dispatch({
        type: CREATE_BOS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
  
    }
  };
  
  export const retrieveBOS = () => async (dispatch: any) => {
  
    try {
      const res = await buildingObjectStructureService.getBuildingObjectStructures();
  
      dispatch({
        type: GET_BOS,
        payload: res.data,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const updateBOS = (StructureId, data) => async (dispatch: any) => {
    try {
      const res = await buildingObjectStructureService.updateBuildingObjectStructure(StructureId, data);
  
      dispatch({
        type: UPDATE_BOS,
        payload: data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
     
      return Promise.reject(err);
    }
  };
  
  export const deleteBOS = (StructureId) => async (dispatch: any) => {
    try {
      await buildingObjectStructureService.removeBuildingObjectStructure(StructureId);
      dispatch({
        type: DELETE_BOS,
        payload: { StructureId },
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  export const findBOSByName= (Name,Start,Length) => async (dispatch: any) => {
    try {
      const res = await buildingObjectStructureService.findByname(Name,Start,Length);
  
      dispatch({
        type: GET_BOS,
        payload: res.data,
      });
   
    } catch (err) {
      console.log(err);
    }
  };
  
  
  