import "./home.css";

import {useSelector} from "react-redux";
import { Link } from "react-router-dom";
import { useState } from "react";

//material ui
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import UserService from "../../services/userService";

export interface HomeProps { }

const Home: React.FC<HomeProps> = () => {

  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const menu = useSelector((state: any) => state.menuReducer);
  
  UserService.getUserInfo()
    .then(response => {
      setfirstName(response.data.Firstname)
      setlastName(response.data.Lastname)
    })
    .catch(e => {
      console.log(e);
    });

    const isOpenMobileMenu = menu;

  return (
    <div className={"wrapper home " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
      <div className="home-container">
       <div className="welcome-text"> Welcome {firstName} {lastName} </div>
       <div className="welcome-text s2">To add a sensor go to <span className="sensors-link"><Link to ="/sensors"> Sensors</Link></span> and click on the add sign<span className="add-sign"><AddCircleOutlineIcon/></span></div>
      </div>
    </div>

  );
};

export default Home;

