import * as yup from 'yup'

export const buildingSchema = yup.object().shape({
    Street: yup.string().required("Street is required"),
    City: yup.string().required("City is required"),
    State: yup.string().required("State is required"),
    NumberOfApartments: yup.number()
        .typeError('Please enter a valid number')
        .required("Number of Apartments is required"),
    Number: yup.number()
        .typeError('Please enter a valid number'),
    PostalCode: yup.string(),
})

