
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apartmentSchema } from "../../Validations/apartmentValidation";
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../store/actions/types';
import { retrieveBuildings } from "../../store/actions/buildingAction";
import { retrieveStructures } from "../../store/actions/apartmentAction";
import { ASSETS_API, SAVE_APARTMENT } from "../../global";
import { Formik, Form, ErrorMessage } from "formik";
import { AxiosError } from "axios";
import { useHistory } from "react-router";

//components
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Label from "../../components/Label/Label";
import DropDown from "../../components/DropDown/DropDown";
import CheckBox from "../../components/CheckBox/CheckBox";
import Upload from "../../components/Upload/Upload";

//material ui
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const AddApartment = () => {
  const [submitted, setSubmitted] = useState(false);
  const buildings = useSelector((state: any) => state.buildingReducer);
  const structures = useSelector((state: any) => state.apartmentReducer);
  const { message } = useSelector((state: any) => state.MessageReducer);

  const dispatch = useDispatch<any>();

  const menu = useSelector((state: any) => state.menuReducer);
  const isOpenMobileMenu = menu;

  useEffect(() => {
    dispatch(retrieveBuildings());
    dispatch(retrieveStructures());
  }, [dispatch]);

  const newApartment = () => {
    setSubmitted(false);
  };

  let history = useHistory();
  const goBack = () => {
    history.goBack()
  };

  //get building options
  let buildingoptions = buildings.map(function (item) {
    return { value: item.BuildingId, label: item.BuildingId + "-" + item.Address };
  })

  //get building object structure options
  let structureoptions = structures.map(function (item) {
    return { value: item.StructureId, label: item.StructureId + "-" + item.Name };
  })

  return (
    <>
      <div className={"main-container " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
        <div className="submit-form">
          <KeyboardBackspaceIcon className="hover-icon" fontSize="large" onClick={goBack}></KeyboardBackspaceIcon>
          <div className="page-title">Add a Apartment</div>
          {submitted ? (
            <div>
              <div><h4>Apartment has been added successfully!</h4></div>
              <Button OnClickEvent={newApartment}>
                Add Another
              </Button>
            </div>
          ) : (
            <Formik
              initialValues={{ BuildingId: '', StructureId: '', IsOccupied: false, Floor: '', Number: '', Size: '', Map: null }}
              onSubmit={(values) => {

                let formData = new FormData();
                formData.append("BuildingId", values.BuildingId);
                formData.append("StructureId", values.StructureId);
                formData.append("IsOccupied", JSON.stringify(values.IsOccupied));
                formData.append("Size", JSON.stringify(values.Size));
                formData.append("Map", values.Map);
                formData.append("Number", values.Number);
                formData.append("Floor", values.Floor);

                return ASSETS_API.post(SAVE_APARTMENT, formData, {
                  method: "POST",
                  headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": 'bearer ' + localStorage.getItem('token'),
                  },
                }).then((response) => {

                  if (response.status !== 200) {
                    dispatch({
                      type: SET_MESSAGE,
                      payload: "An error occurred!",
                    });

                  }
                  if (response.status == 200) {
                    setSubmitted(true);
                    dispatch({
                      type: CLEAR_MESSAGE,
                    });

                    if (response.data.status == 400) {
                      dispatch({
                        type: SET_MESSAGE,
                        payload: "Make sure to upload a file in these formats(.jpg, .jpeg, .pdf, .png, .gif)",
                      });
                      setSubmitted(false);
                    }
                    return response.data;
                  }
                }).catch((error) => {
                  const err = error as AxiosError
                  if (err.response) {
                    if (err.response.status == 401) {

                      dispatch({
                        type: SET_MESSAGE,
                        payload: "You are not authorized to do such action",
                      });
                    }
                    return Promise.reject();
                  }
                })
              }}
              validationSchema={apartmentSchema}
            >
              {(formProps) => (
                <Form>
                  <div className="form-input">
                    <label>Building</label>
                    <div className="form-input-inner">
                      <DropDown
                        items={buildingoptions}
                        onChange={(e) => formProps.setFieldValue("BuildingId",e.value)}
                        name="Buildings"
                        defaultOption="Building"
                      />
                    </div>
                    <div className="error-message"><ErrorMessage name="BuildingId" /></div>
                  </div>
                  <div className="form-input">
                    <label>Structure</label>
                    <div className="form-input-inner">
                      <DropDown
                        items={structureoptions}
                        onChange={(e) => formProps.setFieldValue("StructureId",e.value)}
                        name="Structures"
                        defaultOption="Structure"
                      />
                    </div>
                    <div className="error-message"><ErrorMessage name="StructureId" /></div>
                  </div>
                  <div className="form-input">
                    <label htmlFor="Floor">Floor</label>
                    <Input
                      type="text"
                      id="Floor"
                      OnChangeHandler={(event) => formProps.setFieldValue("Floor", event.target.value)}
                      name="Floor"
                    />
                    <div className="error-message"><ErrorMessage name="Floor" /></div>
                  </div>
                  <div className="form-input">
                    <label htmlFor="Number">Object Number</label>
                    <Input
                      type="text"
                      id="Number"
                      OnChangeHandler={(event) => formProps.setFieldValue("Number", event.target.value)}
                      name="Number"
                    />
                    <div className="error-message"><ErrorMessage name="Number" /></div>
                  </div>
                  <div className="form-input">
                    <label htmlFor="Size">Size</label>
                    <Input
                      type="text"
                      id="Size"
                      OnChangeHandler={(event) => formProps.setFieldValue("Size", event.target.value)}
                      name="Size"
                    />
                    <div className="error-message"><ErrorMessage name="Size" /></div>
                  </div>
                  <Upload
                    OnChangeHandler={(event) => formProps.setFieldValue("Map", event.target.files[0])}
                  />
                  <div className="form-input">
                    <label>Occupied?</label>
                    <CheckBox
                      checked={formProps.values.IsOccupied}
                      onChange={(event) => formProps.setFieldValue('IsOccupied', !formProps.values.IsOccupied)}
                      name="IsOcuppied"
                    />
                  </div>
                  <Button type="submit">
                    Submit
                  </Button>
                  <div><Label>{message}</Label></div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </>
  );
};

export default AddApartment;



