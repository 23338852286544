import "./Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { LogOutAction, retrieveUserInfo } from "../../store/actions/authAction";
import Button from "../../components/Button/Button";
import { NavLink } from 'react-router-dom';
import svgLogo from "../../assets/svg_logo.png";
import { CLEAR_MESSAGE } from "../../store/actions/types";
import { toggleMobileNavigationMenu } from "../../store/actions/menuAction";
import { useEffect, useState } from "react";

//material-ui
import MenuIcon from '@material-ui/icons/Menu';

export interface NavBarProps { }

const Navbar: React.FC<NavBarProps> = () => {

  const menu = useSelector((state: any) => state.menuReducer);
  const user = useSelector((state: any) => state.LoginReducer);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(retrieveUserInfo());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 900;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  const history = useHistory();
  const isOpenMobileMenu = menu;

  const logoutBtnHandler = () => {
    dispatch(LogOutAction());
    dispatch({
      type: CLEAR_MESSAGE,
    });
    history.push("/");
    window.location.replace(window.location.href)
  };

  const toggleMenu = () => {
    dispatch(toggleMobileNavigationMenu())
  };


  return (
    <>
      <div className="wrapper header">
        <div className="create-table full-width">
          <div className="create-row">
            <div className="create-cell">
              <div className="full-width float-left">
                <nav>
                  <div className={"logo-container float-left"}>
                    <a href="/"  >
                      <img src={svgLogo} className={"logo"} alt="SVG company logo" />
                    </a>
                  </div>
                  {localStorage.getItem("user") !== null ? (
                    <div className={"menu-bar float-right " + (isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")} onClick={toggleMenu}>
                      <MenuIcon fontSize="large"></MenuIcon>
                    </div>
                  ) : ""}
                  <div className="menu-items-container desktop-menu float-right">
                    <div className="float-right">

                      {((localStorage.getItem("user") !== null) && user.UserRole == 'Admin') ?
                        (
                          <>
                            <NavLink exact to={{ pathname: "/home" }} activeClassName="active-menu" className="menu-item">
                              Home
                            </NavLink>
                            <NavLink to={{ pathname: "/assets-configurations" }} activeClassName="active-menu" className="menu-item">
                              Assets Configuration
                            </NavLink>
                            <NavLink to={{ pathname: "/buildings" }} activeClassName="active-menu" className="menu-item">
                              Buildings
                            </NavLink>
                            <NavLink to={{ pathname: "/apartments" }} activeClassName="active-menu" className="menu-item">
                              Apartments
                            </NavLink>
                            <NavLink to={{ pathname: "/sensors" }} activeClassName="active-menu" className="menu-item">
                              Sensors
                            </NavLink>
                          </>
                        ) : ""}
                      {((localStorage.getItem("user") !== null) && user.UserRole == 'SL1') ?
                        (
                          <>
                            <NavLink exact to={{ pathname: "/home" }} activeClassName="active-menu" className="menu-item">
                              Home
                            </NavLink>
                            <NavLink to={{ pathname: "/sensors" }} activeClassName="active-menu" className="menu-item">
                              Sensors
                            </NavLink>
                          </>
                        ) : ""}
                      {localStorage.getItem("user") !== null ? (
                        <Link
                          to={{ pathname: "/" }}
                          className="menu-item"
                          onClick={logoutBtnHandler}
                        >
                          <Button>Log out</Button>
                        </Link>
                      ) : ""}
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"mobile-menu " + (isOpenMobileMenu.isMobileMenuvisible && isMobile ? 'active' : "")}>
        <div className="mobile-menu-container">
          {(localStorage.getItem("user") !== null && user.UserRole == 'SL1') ?
            (<>
              <NavLink exact to={{ pathname: "/home" }} activeClassName="active-menu" onClick={toggleMenu}>
                <div className="mobile-menu-item">
                  Home
                </div>
              </NavLink>
              <NavLink to={{ pathname: "/sensors" }} activeClassName="active-menu" onClick={toggleMenu}>
                <div className="mobile-menu-item">
                  Sensors
                </div>
              </NavLink>
            </>
            ) : ""}

          {(localStorage.getItem("user") !== null && user.UserRole == 'Admin') ?
            (<>
              <NavLink exact to={{ pathname: "/home" }} activeClassName="active-menu" onClick={toggleMenu}>
                <div className="mobile-menu-item">
                  Home
                </div>
              </NavLink>
              <NavLink to={{ pathname: "/assets-configurations" }} activeClassName="active-menu" onClick={toggleMenu}>
                <div className="mobile-menu-item">
                  Assets Configuration
                </div>
              </NavLink>
              <NavLink to={{ pathname: "/buildings" }} activeClassName="active-menu" onClick={toggleMenu}>
                <div className="mobile-menu-item">
                  Buildings
                </div>
              </NavLink>
              <NavLink to={{ pathname: "/apartments" }} activeClassName="active-menu" onClick={toggleMenu}>
                <div className="mobile-menu-item">
                  Apartment
                </div>
              </NavLink>
              <NavLink to={{ pathname: "/sensors" }} activeClassName="active-menu" onClick={toggleMenu}>
                <div className="mobile-menu-item">
                  Sensors
                </div>
              </NavLink>
            </>
            ) : ""}

          {localStorage.getItem("user") == null ? (
            <Link to="/login">
              <Button>Login</Button>
            </Link>
          ) : ""}
          {localStorage.getItem("user") !== null ? (
            <Link
              to={{ pathname: "/" }}
              className="menu-item"
              onClick={logoutBtnHandler}
            >
              <Button>Log out</Button>
            </Link>
          ) : ""}
        </div>
      </div>
    </>
  );
};

export default Navbar;
