import "./sensor.css";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { retrieveSensors, findSensorsByFilter, deleteSensor } from "../../store/actions/sensorAction";
import { retrieveBuildings } from "../../store/actions/buildingAction";
import apartmentService from "../../services/apartmentService";
import buildingService from "../../services/buildingService";
import roomService from "../../services/roomService";
import sensorService from "../../services/sensorService";
import { CLEAR_MESSAGE } from "../../store/actions/types";

//material ui
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import Pagination from '@material-ui/lab/Pagination';

// Components:
import Label from "../../components/Label/Label";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import DropDown from "../../components/DropDown/DropDown";

//material-ui
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Tooltip } from "@material-ui/core";

export interface SensorListProps { }
const SensorsList: React.FC<SensorListProps> = () => {

  const sensors = useSelector((state: any) => state.sensorReducer);
  const buildings = useSelector((state: any) => state.buildingReducer);
  const menu = useSelector((state: any) => state.menuReducer);

  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState(false);
  const [active, setActive] = useState(false);

  const [searchTitle, setSearchTitle] = useState("");
  const [clickedSensor, setClickedSensor] = useState("");

  const [apartmentTitle, setApartmentTitle] = useState("");
  const [buildingTitle, setSavedBuildingTitle] = useState("");
  const [roomTitle, setRoomTitle] = useState([]);
  const [controllerTitle, setControllerTitle] = useState("");

  const [hanldeDelete, isHanldeDelete] = useState("");

  const [buildingFilter, setBuildingFilter] = useState("");
  const [apartmentFilter, setApartmentFilter] = useState("");

  const [buildingApartments, setBuildingApartments] = useState([]);

  const [resetFilter, isResetFilter] = useState(false);

  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch<any>();
  const isOpenMobileMenu = menu;

  useEffect(() => {
    dispatch(retrieveSensors(0, pageSize));
    dispatch(retrieveBuildings());
  }, [dispatch]);

  const onChangeSearchTitle = e => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
    refreshData();
    dispatch(findSensorsByFilter(e.target.value, buildingFilter, apartmentFilter, 0, pageSize));
    setActive(false);
  };

  const refreshData = () => {
    retrieveSensors(0, pageSize);
  };

  const gotoSensorInfo = (sensor, index) => {
    sensorService.getSensor(sensor.SensorId)
      .then(response => {
        sensorService.getController(response.data.ControllerId)
          .then(response => {
            setControllerTitle(response.data.SerialNumber)
          })
        if (response.data.RoomId !== undefined) {
          roomService.getRoom(response.data.RoomId)
            .then(response => {
              setRoomTitle(response.data.Name)
            })
        }
        if (response.data.BuildingObjectId !== undefined) {
          apartmentService.getApartment(response.data.BuildingObjectId)
            .then(response => {
              setApartmentTitle(response.data.Address)
              buildingService.getBuilding(response.data.BuildingId)
                .then(response => {
                  setSavedBuildingTitle(response.data.Address)
                })
            })
        }
      })
      .catch(e => {
        console.log(e);
      });

    var sensor_details = document.getElementsByClassName("sensor-details")[index].id;
    setActive(true);
    setClickedSensor(sensor_details)
  }

  const handleClickOpen = (sensor) => {
    setOpen(true);
    isHanldeDelete(sensor)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    dispatch(deleteSensor(hanldeDelete));
  };

  const sortList = () => {
    setSort(!sort)
  };

  //get building options
  let buildingoptions = buildings.filter(item => item.ControllerId !== 0).map(function (item) {
    return { value: item.BuildingId, label: item.BuildingId + "-" + item.Address };
  })

  //get apartment options
  let apartmentoptions = buildingApartments.map(function (item) {
    return { value: item.ApartmentId, label: item.ApartmentId + "-" + item.Address };
  })

  const resetFilters = () => {
    isResetFilter(true)
    setSearchTitle("");
    dispatch(findSensorsByFilter("", "", "", 0, pageSize));
    setActive(false);
  };

  var sortJsonArray = require('sort-json-array');

  const handlePageChange = (e, value) => {
    dispatch(findSensorsByFilter(searchTitle, buildingFilter, apartmentFilter, value * pageSize - pageSize, pageSize));
    setActive(false);
  };

  const handleBuildingClick = () => {
   isResetFilter(false);
  }

  var sortBy;
  sortBy = sort ? sortJsonArray(sensors, 'SensorId', 'des') : sortJsonArray(sensors, 'SensorId', 'asc')

  return (
    <>
      <div className={"wrapper list " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
        <div className="page-title">
          <h1>Sensors List</h1>
        </div>
        <div className="create-table full-width list-items sensor">
          <div className="create-row">
            <div className="create-cell">
              <div className="float-left full-width">
                <div className="float-left search-box">
                  <Input
                    type="text"
                    placeholder="Search..."
                    value={searchTitle}
                    OnChangeHandler={onChangeSearchTitle}
                  />
                </div>
                <div className="float-left filter" onClick={handleBuildingClick}>
                  <DropDown
                    {...(resetFilter && { key: buildingFilter })}
                    items={buildingoptions}
                    onChange={(e) => {
                      if (/\d/.test(e.value)) { //if user selected an building
                        setBuildingApartments([]);
                        setBuildingFilter(e.value)
                        dispatch(findSensorsByFilter(searchTitle, e.value, apartmentFilter, 0, pageSize));
                        buildingService.getBuilding(e.value)
                          .then(response => {
                            if (response.data.Apartments !== undefined) {
                              setBuildingApartments(response.data.Apartments);
                            }
                          })
                          .catch(e => {
                            console.log(e);
                          });
                      }
                      setActive(false);
                    }
                    }
                    name="BuildingId"
                    defaultOption={{ label: "Select Building", value: 0 }}
                  />
                </div>
                <div className="float-left filter">
                  <DropDown
                    {...(resetFilter && { key: apartmentFilter })}
                    items={resetFilter? []: apartmentoptions}
                    onChange={(e) => {
                      if (/\d/.test(e.value)) { //if user selected an building
                        setApartmentFilter(e.value)
                        dispatch(findSensorsByFilter(searchTitle, buildingFilter, e.value, 0, pageSize));
                      }
                      setActive(false);
                    }
                    }
                    name="BuildingId"
                    defaultOption={{ label: "Select Aparment", value: 0 }}
                  />
                </div>
                <div className="float-left reset-icon">
                  <Tooltip title="reset filters">
                    <AutorenewIcon fontSize="large" onClick={() => resetFilters()}></AutorenewIcon>
                  </Tooltip>
                </div>
              </div>
              <span className="float-left page-size-text">Page Size:</span>
              <div className="float-left page-size-filter">
                <DropDown
                  items={[{ value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }]}
                  onChange={(e) => {
                    setPageSize(e.value)
                    dispatch(findSensorsByFilter(searchTitle, buildingFilter, apartmentFilter, 0, e.value));
                    setActive(false);
                  }
                  }
                  defaultOption={{ label: "10", value: 10 }}
                />
              </div>
              <div className="float-right add-icon">
                <NavLink to={{ pathname: "/sensors/add-sensor" }} activeClassName="active-menu">
                  <Tooltip title="add sensor">
                    <AddCircleOutlineIcon className="hover-icon" fontSize="large" onClick={() =>
                      dispatch({
                        type: CLEAR_MESSAGE
                      })} ></AddCircleOutlineIcon></Tooltip>
                </NavLink>
              </div>
              <div className="scroll-list">
                <div className="float-left full-width">
                  <div className="float-left list-item title ID">ID#<div className="sort-icon" onClick={() => { sortList() }}> <Tooltip title="sort sensors"><SyncAltIcon></SyncAltIcon></Tooltip></div></div>
                  <div className="float-left list-item title address">Serail Number</div>
                  <div className="float-left list-item title">Category</div>
                  <div className="float-left list-item title">Manufacturer</div>
                  <div className="float-left list-item title">Sensor Name/Designation</div>
                </div>

                {
                  sensors && sensors.length > 0 &&
                  sortBy.map((sensor, index) => (
                    <>
                      <div className={"float-left full-width list-row " + (clickedSensor == index && active ? 'active' : "")} key={index} >
                        <div className="float-left list-item ID"> {sensor.SensorId}</div>
                        <div className="float-left list-item address"> {sensor.SerialNumber}</div>
                        <div className="float-left list-item"> {sensor.CategoryName}</div>
                        <div className="float-left list-item"> {sensor.Manufacturer}</div>
                        <div className="float-left list-item"> {sensor.TechnicalDisplayName}</div>
                        <div className="float-right info-icon sensor-details" id={index}><Tooltip title="info details"><InfoIcon className="hover-icon" onClick={() => gotoSensorInfo(sensor, index)}></InfoIcon></Tooltip></div>
                        <div className="float-right info-icon">
                          <Tooltip title="delete sensor"><DeleteIcon className="hover-icon" onClick={() => handleClickOpen(sensor.SensorId)}></DeleteIcon></Tooltip>
                        </div>
                        <div className="float-right info-icon">
                          <NavLink to={"/sensors/sensor/" + sensor.SensorId} activeClassName="active-menu">
                            <Tooltip title="edit sensor"><EditIcon className="hover-icon" onClick={() =>
                              dispatch({
                                type: CLEAR_MESSAGE
                              })} /></Tooltip>
                          </NavLink>
                        </div>
                        <div id={index} className={"details-block float-left full-width sensor " + (clickedSensor == index && active ? 'active' : "")} >
                          <div className="details-inner">
                            <div className="details-title"> <h4>Sensor Details</h4></div>
                            <div>
                              <Label>
                                <strong>Serial Number:</strong>
                              </Label>
                              {sensor.SerialNumber}
                            </div>
                            <div>
                              <Label>
                                <strong>Category:</strong>
                              </Label>
                              {sensor.CategoryName}
                            </div>
                            <div>
                              <Label>
                                <strong>Manufacturer:</strong>
                              </Label>
                              {sensor.Manufacturer}
                            </div>
                            <div>
                              <Label>
                                <strong>Module Type:</strong>
                              </Label>
                              {sensor.TechnicalDisplayName}
                            </div>
                            <div>
                              <Label>
                                <strong>Building:</strong>
                              </Label>
                              {buildingTitle}
                            </div>
                            <div>
                              <Label>
                                <strong>Apartment:</strong>
                              </Label>
                              {apartmentTitle}
                            </div>
                            <div>
                              <Label>
                                <strong>Room:</strong>
                              </Label>
                              {roomTitle}
                            </div>
                            <div>
                              <Label>
                                <strong>Controller:</strong>
                              </Label>
                              {controllerTitle}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              <div>
                <div className="float-right pagination-top">
                  {(Array.isArray(sensors) && sensors.length)?<Pagination size="small" count={Math.ceil(sensors[0].TotalCount / pageSize)} onChange={handlePageChange} /> : ""}
                </div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
                  <DialogContent>
                  </DialogContent>
                  <DialogActions>
                    <Button OnClickEvent={() => handleClose()} >
                      No
                    </Button>
                    <Button OnClickEvent={() => handleConfirm()} >
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SensorsList;
