import "./apartment.css";
import React, { useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import apartmentService from "../../services/apartmentService";
import { deleteApartment, retrieveApartments, findApartmentsByAddress } from '../../store/actions/apartmentAction';
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_MESSAGE } from "../../store/actions/types";

//material ui
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import Pagination from '@material-ui/lab/Pagination';

// Components:
import Label from "../../components/Label/Label";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import DropDown from "../../components/DropDown/DropDown";

export interface ApartmentListProps { }

const ApartmentsList: React.FC<ApartmentListProps> = () => {

  const apartments = useSelector((state: any) => state.apartmentReducer);
  const [currentApartment, setCurrentApartment] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");
  const [clickedApartment, setClickedApartment] = useState("");

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [hanldeDelete, isHanldeDelete] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch<any>();

  const menu = useSelector((state: any) => state.menuReducer);
  const isOpenMobileMenu = menu;

  useEffect(() => {
    dispatch(findApartmentsByAddress(searchTitle,0, pageSize));
  }, [dispatch]);

  const onChangeSearchTitle = e => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
    refreshData();
    dispatch(findApartmentsByAddress(searchTitle,0, pageSize));
  };

  const refreshData = () => {
    retrieveApartments();
    setCurrentApartment(null);
  };

  const handleClickOpen = (sensor) => {
    setOpen(true);
    isHanldeDelete(sensor)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    dispatch(deleteApartment(hanldeDelete));
  };

  const gotoApartmentInfo = (apartment, index) => {
    apartmentService.getApartment(apartment.ApartmentId)
      .then(response => {
        setCurrentApartment(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    var apartment_details = document.getElementsByClassName("apartment-details")[index].id;
    setActive(true);
    setClickedApartment(apartment_details)
  }

  const handlePageChange = (e, value) => {
    dispatch(findApartmentsByAddress(searchTitle, value * pageSize - pageSize, pageSize));
    setActive(false);
  };

  return (
    <>
      <div className={"wrapper list " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
        <div className="page-title"><h1>Apartments List</h1></div>
        <div className="create-table full-width list-items apartment">
          <div className="create-row">
            <div className="create-cell">
              <div className="float-left full-width">
                <div className="float-left search-box">
                  <Input
                    type="text"
                    placeholder="Search by address"
                    value={searchTitle}
                    OnChangeHandler={onChangeSearchTitle}
                  />
                </div>
                <div className="float-left full-width">
                    <span className="float-left page-size-text">Page Size:</span>
                    <div className="float-left page-size-filter">
                      <DropDown
                        items={[{ value: '10', label: '10' },
                        { value: '25', label: '25' },
                        { value: '50', label: '50' },
                        { value: '100', label: '100' }]}
                        onChange={(e) => {
                          setPageSize(e.value)
                          dispatch(findApartmentsByAddress(searchTitle, 0, e.value));
                          setActive(false);
                        }
                        }
                        defaultOption={{ label: "10", value: 10 }}
                      />
                    </div>
                  </div>
                <div className="float-right add-icon">
                  <NavLink to={{ pathname: "/apartments/add-apartment" }} activeClassName="active-menu">
                    <AddCircleOutlineIcon className="hover-icon" fontSize="large" onClick={() =>
                      dispatch({
                        type: CLEAR_MESSAGE
                      })} ></AddCircleOutlineIcon>
                  </NavLink>
                </div>
              </div>
              <div className="scroll-list apartment">
                <div className="float-left full-width">
                  <div className="float-left list-item title ID">ID#</div>
                  <div className="float-left list-item title address">Address</div>
                  <div className="float-left list-item title"> Floor Number</div>
                  <div className="float-left list-item title">Object Number</div>
                  <div className="float-left list-item title">Occupied</div>
                </div>
                {apartments && apartments.length > 0 &&
                  apartments.map((apartment, index) => (
                    <div className={"float-left full-width list-row " + (clickedApartment == index && active ? 'active' : "")} key={index} >
                      <div className="float-left list-item  ID">{apartment.ApartmentId}</div>
                      <div className="float-left list-item address"> {apartment.Address}</div>
                      <div className="float-left list-item"> {apartment.Floor}</div>
                      <div className="float-left list-item"> {apartment.Number}</div>
                      <div className="float-left list-item"> {apartment.IsOccupied ? 'yes' : 'no'}</div>
                      <div className="float-right info-icon apartment-details" id={index}> <InfoIcon className="hover-icon" onClick={() => gotoApartmentInfo(apartment, index)}></InfoIcon></div>
                      <div className="float-right info-icon"> <DeleteIcon className="hover-icon" onClick={() => handleClickOpen(apartment.ApartmentId)}></DeleteIcon></div>
                      <div className="float-right info-icon">
                        <NavLink to={"/apartments/apartment/" + apartment.ApartmentId} activeClassName="active-menu">
                          <EditIcon className="hover-icon" onClick={() =>
                            dispatch({
                              type: CLEAR_MESSAGE
                            })} />
                        </NavLink>
                      </div>
                      <div id={index} className={"details-block float-left full-width sensor " + (clickedApartment == index && active ? 'active' : "")} >
                        <div className="details-inner">
                          {currentApartment ? (
                            <div>
                              <div className="details-title"> <h4>Apartment Details</h4></div>
                              <div>
                                <Label>
                                  <strong>Floor Number:</strong>
                                </Label>
                                {currentApartment.Floor}
                              </div>
                              <div>
                                <Label>
                                  <strong>Object Number:</strong>
                                </Label>
                                {currentApartment.Number}
                              </div>
                              <div>
                                <Label>
                                  <strong>Size:</strong>
                                </Label>
                                {currentApartment.Size}
                              </div>
                              <div>
                                <Label>
                                  <strong>Occupied:</strong>
                                </Label>
                                {currentApartment.IsOccupied ? 'yes' : 'no'}
                              </div>
                              {/*{(() => {
                                if (currentApartment.Map != null) {
                                  if (currentApartment.Map.match(/.(pdf)$/i)) {
                                    return (
                                      <a href={SVG_WEB_API + "Assets/GetApartmentMap?filename=" + currentApartment.Map} target="_blank">Map (pdf)</a>
                                    )
                                  } else
                                    return (
                                      <>
                                        <div><Label><strong>Map:</strong> </Label></div>
                                        <img src={SVG_WEB_API + "Assets/GetApartmentMap?filename=" + currentApartment.Map} className={"map"} alt="Map" />
                                      </>
                                    )
                                }
                              })()}*/}

                              {currentApartment.Rooms && currentApartment.Rooms.length > 0 && (
                                <div>
                                  <div className="related-title"> <h3>Related Rooms</h3></div>
                                  <div className="create-table full-width list-items related-apartments">
                                    <div className="create-row">
                                      <div className="create-cell">
                                        <div className="float-left full-width">
                                          <div className="float-left list-item title ID">ID#</div>
                                          <div className="float-left list-item title"> Room Name</div>
                                        </div>
                                        {(currentApartment.Rooms || []).map((room, index) => (
                                          <div className="float-left full-width list-row" key={index}>
                                            <div className="float-left list-item ">{room.RoomId}</div>
                                            <div className="float-left list-item ">{room.Name}</div>

                                            <div className="float-right info-icon">
                                              <NavLink to={"assets-configurations/room/" + room.RoomId} activeClassName="active-menu">
                                                <EditIcon className="hover-icon" />
                                              </NavLink>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="list-end"></div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                <div className="float-right pagination-top">
                  {(Array.isArray(apartments) && apartments.length) ? <Pagination size="small" count={Math.ceil(apartments[0].TotalCount / pageSize)} onChange={handlePageChange} /> : ""}
                </div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
                  <DialogContent>
                  </DialogContent>
                  <DialogActions>
                    <Button OnClickEvent={() => handleClose()} >
                      No
                    </Button>
                    <Button OnClickEvent={() => handleConfirm()} >
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApartmentsList;
