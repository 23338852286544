import { useState, useEffect } from "react";
import apartmentService from "../../services/apartmentService";
import { retrieveStructures } from '../../store/actions/apartmentAction';
import { useDispatch, useSelector } from "react-redux";
import { SET_MESSAGE } from '../../store/actions/types'
import { retrieveBuildings } from "../../store/actions/buildingAction";
import { Formik, Form, ErrorMessage } from "formik";
import { apartmentSchema } from "../../Validations/apartmentValidation";
import { ASSETS_API,  UPDATE_APARTMENT } from "../../global";
import { AxiosError } from "axios";
import { useHistory } from "react-router";

//components
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import DropDown from "../../components/DropDown/DropDown";
import CheckBox from "../../components/CheckBox/CheckBox";
import Upload from "../../components/Upload/Upload";

//material ui
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export interface ApartmentProps { }

const Apartment = (props) => {
  const initialApartmentState = {
    ApartmentId: null,
    BuildingId: '',
    StructureId: '',
    IsOccupied: false,
    Floor: '',
    Number: "",
    Size: 0.0,
    Map: "",
    Rooms: []
  };

  const [currentApartment, setCurrentApartment] = useState(initialApartmentState);
  const [occupied, checkIsOccupied] = useState(false);

  const { message } = useSelector((state: any) => state.MessageReducer);
  const buildings = useSelector((state: any) => state.buildingReducer);
  const structures = useSelector((state: any) => state.apartmentReducer);
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedStructure, setSelectedStructure] = useState("");
  const [preSavedBuilding, setPreSavedBuilding] = useState("");


  const [savedBuildingId, setSavedBuildingId] = useState("");
  const [savedStructureId, setSavedStructureId] = useState("");

  const dispatch = useDispatch<any>();

  const menu = useSelector((state: any) => state.menuReducer);
  const isOpenMobileMenu = menu;

  useEffect(() => {
    dispatch(retrieveBuildings());
    dispatch(retrieveStructures());
  }, [dispatch]);

  let savedBuilding;
  const getApartment = id => {
    apartmentService.getApartment(id)
      .then(response => {
        setCurrentApartment(response.data);

        setSavedBuildingId(response.data.BuildingId)
        setPreSavedBuilding(savedBuilding);
        if (response.data.StructureId !== undefined) {
          setSavedStructureId(response.data.StructureId);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getApartment(props.match.params.id);
  }, [props.match.params.id]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentApartment({ ...currentApartment, [name]: value });
  };

  let history = useHistory();
  const goBack = () => {
    history.goBack()
  };

  //get building options
  let buildingoptions = buildings.map(function (item) {
    return { value: item.BuildingId, label: item.BuildingId + "-" + item.Address };
  })

  //get building object structure options
  let structureoptions = structures.map(function (item) {
    return { value: item.StructureId, label: item.StructureId + "-" + item.Name };
  })

  //get selected Building value
  let selectedBuildingValue = buildings.filter(item => item.BuildingId == selectedBuilding).map(function (item) {
    return { value: item.BuildingId, label: item.BuildingId + "-" + item.Address };
  })

  //get selected Structure value
  let selectedStructureValue = structures.filter(item => item.StructureId == selectedStructure).map(function (item) {
    return { value: item.StructureId, label: item.StructureId + "-" + item.Name };
  })

  //get building saved value
  savedBuilding = buildings.filter(item => item.BuildingId == savedBuildingId).map(function (item) {
    return { value: item.BuildingId, label: item.BuildingId + "-" + item.Address };
  })

  //get structure saved value
  let savedStructure = structures.filter(item => item.StructureId == savedStructureId).map(function (item) {
    return { value: item.StructureId, label: item.StructureId + "-" + item.Name };
  })

  return (
    <>
      <div className={"main-container " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
        <div className="submit-form">
          <KeyboardBackspaceIcon className="hover-icon" fontSize="large" onClick={goBack}></KeyboardBackspaceIcon>
          <div className="page-title">Edit Apartment</div>
          <Formik
            initialValues={currentApartment}
            onSubmit={(values) => {

              let formData = new FormData();
              formData.append("BuildingId", values.BuildingId);
              formData.append("StructureId", values.StructureId);
              formData.append("IsOccupied", JSON.stringify(values.IsOccupied));
              formData.append("Size", JSON.stringify(values.Size));
              formData.append("Map", values.Map);
              formData.append("Number", values.Number);
              formData.append("Floor", values.Floor);

              return ASSETS_API.put(UPDATE_APARTMENT + props.match.params.id, formData, {
                method: "PUT",
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Authorization": 'bearer ' + localStorage.getItem('token'),
                },
              }).then((response) => {
                if (response.status !== 200) {
                  dispatch({
                    type: SET_MESSAGE,
                    payload: "An error occurred!",
                  });
                }
                if (response.status == 200) {
                  dispatch({
                    type: SET_MESSAGE,
                    payload: "The apartment was updated successfully!",
                  });
                  if (response.data.status == 400) {
                    dispatch({
                      type: SET_MESSAGE,
                      payload: "Make sure to upload a file in these formats(.jpg, .jpeg, .pdf, .png, .gif)",
                    });
                  }
                  return response.data;
                }
              }).catch((error) => {
                const err = error as AxiosError
                if (err.response) {
                  if (err.response.status == 401) {

                    dispatch({
                      type: SET_MESSAGE,
                      payload: "You are not authorized to do such action",
                    });
                  }
                  return Promise.reject();
                }
              })
            }}
            validationSchema={apartmentSchema}
            enableReinitialize={true}
          >
            {(formProps) => {
              const { values, setFieldValue } = formProps;
              return (
                <Form>
                  <div className="form-input">
                    <label>Building</label>
                    <DropDown
                      items={buildingoptions}
                      onChange={(e) => {
                        const selectedBuilding = e.value;
                        setSelectedBuilding(selectedBuilding);
                        setCurrentApartment({ ...currentApartment, BuildingId: e.value });
                        formProps.setFieldValue("BuildingId", e.value)
                      }}
                      name="Buildings"
                      defaultOption="Building"
                      selectedItem={selectedBuilding ? selectedBuildingValue : savedBuilding}
                    />
                    <div className="error-message"><ErrorMessage name="BuildingId" /></div>
                  </div>
                  <div className="form-input">
                    <label>Structure</label>
                    <DropDown
                      items={structureoptions}
                      onChange={(e) => {
                        const selectedStructure = e.value;
                        setSelectedStructure(selectedStructure);
                        setCurrentApartment({ ...currentApartment, StructureId: e.value });
                        formProps.setFieldValue("StructureId", e.value)
                      }
                      }
                      name="Structures"
                      defaultOption="Structure"
                      selectedItem={selectedStructure ? selectedStructureValue : savedStructure}
                    />
                    <div className="error-message"><ErrorMessage name="StructureId" /></div>
                  </div>
                  <div className="form-input">
                    <label htmlFor="Floor">Floor</label>
                    <Input
                      type="text"
                      id="Floor"
                      name="Floor"
                      value={values.Floor}
                      OnChangeHandler={handleInputChange}
                    />
                    <div className="error-message"><ErrorMessage name="Floor" /></div>
                  </div>
                  <div className="form-input">
                    <label htmlFor="Number">Object Number</label>
                    <Input
                      type="text"
                      id="Number"
                      name="Number"
                      value={values.Number}
                      OnChangeHandler={handleInputChange}
                    />
                    <div className="error-message"><ErrorMessage name="Number" /></div>
                  </div>
                  <div className="form-input">
                    <label htmlFor="Size">Size</label>
                    <Input
                      type="text"
                      id="Size"
                      name="Size"
                      value={values.Size}
                      OnChangeHandler={handleInputChange}
                    />
                    <div className="error-message"><ErrorMessage name="Size" /></div>
                  </div>
                  <Upload
                    OnChangeHandler={(event) => formProps.setFieldValue("Map", event.target.files[0])}
                  />
                  {/*  <div className="form-input">
                    {(() => {
                      if (currentApartment.Map != null) {
                        if (currentApartment.Map.match(/.(pdf)$/i)) {
                          return (
                            <a href={SVG_WEB_API + "Assets/GetApartmentMap?filename=" + currentApartment.Map} target="_blank">Map (pdf)</a>
                          )
                        } else
                          return (
                            <>
                              <div><label>Map: </label></div>
                              <img src={SVG_WEB_API + "Assets/GetApartmentMap?filename=" + currentApartment.Map} className={"map"} alt="Map" />
                            </>
                          )
                      }
                    })()}
                  </div>*/}
                  <div className="form-input">
                    <label>Occupied?</label>
                    <CheckBox
                      checked={values.IsOccupied}
                      onChange={(event) => {
                        checkIsOccupied(!occupied)
                        setCurrentApartment({ ...currentApartment, IsOccupied: !occupied });
                        setFieldValue('IsOccupied', occupied);
                      }
                      }
                    />
                  </div>
                  <Button
                    type="submit"
                  >
                    Update
                  </Button>
                </Form>
              );
            }}
          </Formik>
          <p>{message}</p>
        </div>

      </div>
    </>
  );
};

export default Apartment;
