import {
    GET_APARTMENTS,
    UPDATE_APARTMENT,
    DELETE_APARTMENT,
    GET_STRUCTURES
  } from "./types";
  
  import apartmentService from "../../services/apartmentService";
  
  
  export const retrieveApartments = () => async (dispatch: any) => {
  
    try {
      const res = await apartmentService.getApartments();
  
      dispatch({
        type: GET_APARTMENTS,
        payload: res.data,
      });
    
    } catch (err) {
      console.log(err);
    }
  };
  
  export const updateApartment = (ApartmentId, data) => async (dispatch: any) => {
    try {
      const res = await apartmentService.updateApartment(ApartmentId, data);
  
      dispatch({
        type: UPDATE_APARTMENT,
        payload: data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
     
      return Promise.reject(err);
    }
  };
  
  export const deleteApartment = (ApartmentId) => async (dispatch: any) => {
    try {
      await apartmentService.removeApartment(ApartmentId);
      dispatch({
        type: DELETE_APARTMENT,
        payload: { ApartmentId },
      });
    
    } catch (err) {
      console.log(err);
    }
  };
  
  export const findApartmentsByAddress = (Address,Start,Length) => async (dispatch: any) => {
    try {
      const res = await apartmentService.findByAddress(Address,Start,Length);
  
      dispatch({
        type: GET_APARTMENTS,
        payload: res.data,
      });
   
    } catch (err) {
      console.log(err);
    }
  };
  
  export const retrieveStructures = () => async (dispatch: any) => {
    try {
      const res = await apartmentService.getStructures();
  
      dispatch({
        type: GET_STRUCTURES,
        payload: res.data,
      });
   
    } catch (err) {
      console.log(err);
    }
  };
  
  