import { useState, useEffect } from "react";
import buildingObjectStructureService from "../../services/buildingObjectStructureService";
import { updateBOS } from '../../store/actions/buildingObjectStructureAction';
import { useDispatch, useSelector } from "react-redux";
import { SET_MESSAGE } from '../../store/actions/types'
import { retrieveRooms } from "../../store/actions/roomAction";
import { Formik, Form, ErrorMessage } from "formik";
import { useHistory } from "react-router";
import { buildingObjectStructureSchema } from "../../Validations/buildingObjectStructureValidation";

//components
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import DropDown from "../../components/DropDown/DropDown";

//material ui
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export interface BOSProps { }

const EditStructure = (props) => {
  const initialStructureState = {
    StructureId: null,
    Name: "",
    NumOfRooms: 0,
    SelectedRoomsIds: []
  };

  const [currentBOS, setCurrentBOS] = useState(initialStructureState);
  const { message } = useSelector((state: any) => state.MessageReducer);
  const rooms = useSelector((state: any) => state.roomReducer);
  const [numberofRooms, setNumberofRooms] = useState(0);

  const [savedRooms, setsavedRooms] = useState([]);
  const dispatch = useDispatch<any>();

  const menu = useSelector((state: any) => state.menuReducer);
  const isOpenMobileMenu = menu;

  useEffect(() => {
    dispatch(retrieveRooms());
  }, [dispatch]);

  const getBOS = id => {
    buildingObjectStructureService.getBuildingObjectStructure(id)
      .then(response => {
        setCurrentBOS(response.data);
        //get saved options
        const saved_options = response.data.Rooms.map(function (item) {
          return { value: item.RoomId, label: item.Name };
        })
        const saved_rooms = []
        saved_options.forEach((option) => {
          saved_rooms.push(option.value);
        });
        setsavedRooms(saved_options)
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getBOS(props.match.params.id);
  }, [props.match.params.id]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentBOS({ ...currentBOS, [name]: value });
  };

  //get room options
  let options = rooms.map(function (item) {
    return { value: item.RoomId, label: item.Name };
  })

  let history = useHistory();
  const goBack = () => {
    history.goBack()
  };

  return (
    <>
      <div className={"main-container " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
        <div className="submit-form">
          <KeyboardBackspaceIcon className="hover-icon" fontSize="large" onClick={goBack}></KeyboardBackspaceIcon>
          <div className="page-title">Edit Building Object Structure</div>
          <Formik
            initialValues={currentBOS}
            onSubmit={(values) => {
              dispatch(updateBOS(currentBOS.StructureId, currentBOS))
                .then(response => {
                  if (response.status != 200) {
                    dispatch({
                      type: SET_MESSAGE,
                      payload: response.message,
                    });
                  }
                  else {
                    dispatch({
                      type: SET_MESSAGE,
                      payload: "The building Object Structure was updated successfully!",
                    });
                  }
                })
                .catch(e => {
                  if (e.response.status == 401) {
                    dispatch({
                      type: SET_MESSAGE,
                      payload: "You are not authorized to do such action",
                    });
                  }
                  console.log(e);
                });
            }}
           validationSchema={buildingObjectStructureSchema}
            enableReinitialize={true}
          >
            {(formProps) => {
              const { values, setFieldValue } = formProps;
              return (
                <Form>
                  <div className="form-input">
                    <label htmlFor="Name">Name</label>
                    <Input
                      type="text"
                      id="Name"
                      name="Name"
                      value={values.Name}
                      OnChangeHandler={handleInputChange}
                    />
                    <div className="error-message"><ErrorMessage name="Name" /></div>
                  </div>
                  <div>
                    <Input
                      type="hidden"
                      id="NumOfRooms"
                      name="NumOfRooms"
                      value={values.NumOfRooms}
                      OnChangeHandler={handleInputChange}
                    />
                  </div>
                  <div className="form-input">
                    <label>Room(s)</label>
                    <div className="form-input-inner">
                      <DropDown
                        items={options}
                        onChange={(value) => {
                          setNumberofRooms(value.length);
                          const selected_rooms = []
                          value.forEach((option) => {
                            selected_rooms.push(option.value);
                          });
                          console.log("SelectedRoomsIds", value)
                          setCurrentBOS({ ...currentBOS, SelectedRoomsIds: selected_rooms });
                          setCurrentBOS({ ...currentBOS, NumOfRooms: value.length });
                          setsavedRooms(undefined)
                          formProps.setFieldValue("SelectedRoomsIds",selected_rooms);
                          formProps.setFieldValue('NumOfRooms', value.length);
                        }
                        }
                        name="SelectedRoomsIds"
                        selectedItem={savedRooms}
                        isMulti={true}
                      />
                    </div>
                    <div className="error-message"><ErrorMessage name="NumOfRooms" /></div>
                  </div>
                  <Button type="Submit" >
                    Update
                  </Button>
                </Form>
              );
            }}
          </Formik>
          <p>{message}</p>
        </div>
      </div>
    </>
  );
};

export default EditStructure;
