import "./login.css";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction, retrieveUserInfo } from "../../store/actions/authAction";
import ClipLoader from "react-spinners/ClipLoader";
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../store/actions/types';
import { useFormik } from "formik";
import { userSchema } from "../../Validations/userValidation";

// Components:
import Label from "../../components/Label/Label";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import UserService from "../../services/userService";

export interface LoginProps { }

const Login: React.FC<LoginProps> = () => {

  const dispatch = useDispatch<any>();

  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state: any) => state.LoginReducer);
  const { message } = useSelector((state: any) => state.MessageReducer);

  const { handleSubmit, handleChange, values, touched, errors, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: userSchema,

      onSubmit: ({ username, password }) => {
        setLoading(true);
        dispatch(LoginAction(username, password))
          .then((data: any) => {
            if (data.access_token) {
              localStorage.setItem("token", data.access_token);
              dispatch({
                type: CLEAR_MESSAGE
              });
              dispatch(retrieveUserInfo()); 
              UserService.getUserInfo()
              .then(response => {
                console.log("user",response)
              })
              .catch(e => {
                console.log(e);
                console.log("error")
              });
            }
            setLoading(false);
          })
          .catch((err) => {
            if (err.response) {
              // Request made and server responded
              console.log(err.response.data);
              console.log(err.response.status);
              console.log(err.response.headers);
            } else if (err.request) {
              // The request was made but no response was received
              console.log(err.request);
            } else {
              dispatch({
                type: SET_MESSAGE,
                payload: "Wrong username or password",
              });
            }
            setLoading(false);
          });
      }
    });

  if (isLoggedIn) {
    return <Redirect to="/home" />;
  }

  return (
    <div>
      <>
        <div className="login-form">
          <h3 className="login-title"> ANMELDUNG</h3>
          <form onSubmit={handleSubmit}>
            <div className="login-label"><Label>Benutzername *</Label></div>
            <div> <Input
              id="username"
              type="text"
              value={values.username}
              OnChangeHandler={handleChange}
              name="username"
              onBlur={handleBlur}
            />
              {touched.username && errors.username ? (
                <div className="error-message">
                  {errors.username}
                </div>
              ) : null}
            </div>
            <div className="login-label"> <Label>Passwort *</Label></div>
            <div> <Input
              id="password"
              type="password"
              value={values.password}
              OnChangeHandler={handleChange}
              name="password"
              onBlur={handleBlur}
            />
              {touched.password && errors.password ? (
                <div className="error-message">
                  {errors.password}
                </div>
              ) : null}
              <Label> {message}</Label></div>
            {loading ? (
              <ClipLoader
                color={"#7CC4CC"}
                loading={loading}
                size={50}
              />
            ) : (
              <div className="login-btn"><Button>Anmelden</Button></div>
            )}
          </form>
        </div>
      </>
    </div>
  );
};

export default Login;
