
import "./buildingObjectStructure.css";
import React, { useState, useEffect} from "react";
import {  NavLink } from "react-router-dom";
import buildingObjectStructureService from "../../services/buildingObjectStructureService";
import { useDispatch, useSelector } from "react-redux";
import { retrieveBOS, findBOSByName, deleteBOS } from "../../store/actions/buildingObjectStructureAction";
import { CLEAR_MESSAGE } from "../../store/actions/types";

//material ui
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Pagination from '@material-ui/lab/Pagination';


// Components:
import Label from "../../components/Label/Label";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import DropDown from "../../components/DropDown/DropDown";


export interface BOSListProps { }
const BOSsList: React.FC<BOSListProps> = () => {

  const buildingObjectStructures = useSelector((state: any) => state.buildingObjectStructureReducer);
 
  const [currentBOS, setCurrentBOS] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");
  const [clickedStructure, setClickedStructure] = useState("");
  const [active, setActive] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [hanldeDelete, isHanldeDelete] = useState("");

  const dispatch = useDispatch<any>();

  const menu = useSelector((state: any) => state.menuReducer);
  const isOpenMobileMenu = menu;

  useEffect(() => {
    dispatch(findBOSByName(searchTitle, 0, pageSize));
  }, [dispatch]);

  const onChangeSearchTitle = e => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
    refreshData();
    dispatch(findBOSByName(searchTitle, 0, pageSize));
  };

  const refreshData = () => {
    retrieveBOS();
    setCurrentBOS(null);
  };

  const gotoBOSInfo = (buildingObjectStructure, index) => {
    buildingObjectStructureService.getBuildingObjectStructure(buildingObjectStructure.StructureId)
      .then(response => {
        setCurrentBOS(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    var structure_details = document.getElementsByClassName("structure-details")[index].id;
    setActive(true);
    setClickedStructure(structure_details)
  }

  const handleClickOpen = (sensor) => {
    setOpen(true);
    isHanldeDelete(sensor)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    dispatch(deleteBOS(hanldeDelete));
  };

  const handlePageChange = (e, value) => {
    dispatch(findBOSByName(searchTitle, value * pageSize - pageSize, pageSize));
    setActive(false);
  };

  return (
    <>
      <div className={"wrapper list " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
        <div className="page-title"><h1>Building Object Structures List</h1></div>
        <div className="create-table full-width list-items buildingObjectStructure">
          <div className="create-row">
            <div className="create-cell">
              <div className="float-left full-width">
                <div className="float-left search-box">
                  <Input
                    type="text"
                    placeholder="Search by name"
                    value={searchTitle}
                    OnChangeHandler={onChangeSearchTitle}
                  />
                </div>
                <div className="float-left full-width">
                  <span className="float-left page-size-text">Page Size:</span>
                  <div className="float-left page-size-filter">
                    <DropDown
                      items={[{ value: '10', label: '10' },
                      { value: '25', label: '25' },
                      { value: '50', label: '50' },
                      { value: '100', label: '100' }]}
                      onChange={(e) => {
                        setPageSize(e.value)
                        dispatch(findBOSByName(searchTitle, 0, e.value));
                        setActive(false);
                      }
                      }
                      defaultOption={{ label: "10", value: 10 }}
                    />
                  </div>
                </div>
                <div className="float-right add-icon">
                  <NavLink to={{ pathname: "/assets-configurations/add-building-object-structure" }} activeClassName="active-menu">
                    <AddCircleOutlineIcon className="hover-icon" fontSize="large" onClick={() =>
                      dispatch({
                        type: CLEAR_MESSAGE
                      })}
                    ></AddCircleOutlineIcon>
                  </NavLink>
                </div>
              </div>
              <div className="scroll-list structure">
                <div className="float-left full-width">
                  <div className="float-left list-item title ID"> ID#</div>
                  <div className="float-left list-item title"> Name</div>
                  <div className="float-left list-item title"> Number of Rooms</div>
                </div>
                {buildingObjectStructures && buildingObjectStructures.length > 0 &&
                  buildingObjectStructures.map((buildingObjectStructure, index) => (
                    <div className={"float-left full-width list-row " + (clickedStructure == index && active ? 'active' : "")} key={index} >
                      <div className="float-left list-item ID"> {buildingObjectStructure.StructureId}</div>
                      <div className="float-left list-item address"> {buildingObjectStructure.Name}</div>
                      <div className="float-left list-item address"> {buildingObjectStructure.NumOfRooms}</div>
                      <div className="float-right info-icon structure-details" id={index}> <InfoIcon className="hover-icon" onClick={() => gotoBOSInfo(buildingObjectStructure, index)}></InfoIcon></div>
                      <div className="float-right info-icon"> <DeleteIcon className="hover-icon" onClick={() => handleClickOpen(buildingObjectStructure.StructureId)}></DeleteIcon></div>
                      <div className="float-right info-icon">
                        <NavLink to={"/assets-configurations/buildingObjectStructure/" + buildingObjectStructure.StructureId} activeClassName="active-menu">
                          <EditIcon className="hover-icon" onClick={() =>
                            dispatch({
                              type: CLEAR_MESSAGE
                            })} />
                        </NavLink>
                      </div>
                      <div id={index} className={"details-block float-left full-width sensor " + (clickedStructure == index && active ? 'active' : "")} >
                        <div className="details-inner">
                          {currentBOS ? (
                            <div>
                              <div className="details-title"> <h4>Building Object Structure Details</h4></div>
                              <div>
                                <Label>
                                  <strong>Name:</strong>
                                </Label>
                                {currentBOS.Name}
                              </div>
                              <div>
                                <Label>
                                  <strong>Number of Rooms:</strong>
                                </Label>
                                {currentBOS.NumOfRooms}
                              </div>
                              {currentBOS.Apartments && currentBOS.Apartments.length > 0 && (
                                <div>
                                  <div className="related-title"> <h3>Related Apartments</h3></div>
                                  <div className="create-table full-width list-items related-apartments">
                                    <div className="create-row">
                                      <div className="create-cell">
                                        <div className="float-left full-width">
                                          <div className="float-left list-item title ID"> ID#</div>
                                          <div className="float-left list-item title address"> Floor</div>
                                          <div className="float-left list-item title address"> Occupied</div>
                                          <div className="float-left list-item title address"> Number</div>
                                        </div>
                                        {(currentBOS.Apartments || []).map((apartment, index) => (
                                          <div className="float-left full-width list-row" key={index}>
                                            <div className="float-left list-item ID">{apartment.ApartmentId}</div>
                                            <div className="float-left list-item">{apartment.Floor}</div>
                                            <div className="float-left list-item">{apartment.IsOccupied ? 'yes' : 'no'}</div>
                                            <div className="float-left list-item">{apartment.Number}</div>
                                            <div className="float-right info-icon">
                                              <NavLink to={"/apartments/apartment/" + apartment.ApartmentId} activeClassName="active-menu">
                                                <EditIcon className="hover-icon" />
                                              </NavLink>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  <a href="/apartments">Manage All Apartments</a>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="list-end"></div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                <div className="float-right pagination-top">
                  {(Array.isArray(buildingObjectStructures) && buildingObjectStructures.length) ? <Pagination size="small" count={Math.ceil(buildingObjectStructures[0].TotalCount / pageSize)} onChange={handlePageChange} /> : ""}
                </div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
                  <DialogContent>
                  </DialogContent>
                  <DialogActions>
                    <Button OnClickEvent={() => handleClose()} >
                      No
                    </Button>
                    <Button OnClickEvent={() => handleConfirm()} >
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default BOSsList;
