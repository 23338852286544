import { ASSETS_API } from "../global";
//import authHeader from "./authHeader";

//const API_URL = "/";

//const getAdminBoard = () => {
//  return ASSETS_API.get(API_URL + "admin", { headers: authHeader() });
//};

const getUserInfo = () => {
  return ASSETS_API.get(`User/GetInformation`);
};

const UserService = {
  getUserInfo
};

export default UserService;
