import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_MESSAGE ,GET_USER_INFO} from "./types";

import AuthService from "../../services/authService";
import UserService from "../../services/userService";

export const LoginAction = (username, password) => (dispatch: any) => {

  return AuthService.Login({ username, password }).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      window.location.replace(window.location.href)
      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
   
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
  
};

export const LogOutAction = () => (dispatch) => {
  AuthService.Logout();
  dispatch({
    type: LOGOUT,
  });
};

export const retrieveUserInfo = () => async (dispatch: any) => {
  try {
    const res = await UserService.getUserInfo();
    dispatch({
      type: GET_USER_INFO,
      payload: res.data,
    });
  
  } catch (err) {
    console.log(err);
  }
};
