import BOSsList from "../buildingObjectStructure/buildingObjectStructureList";
import RoomsList from "../room/roomList";

export interface RoomProps { }

const AssetsConfiguration = (props) => {
    return (
        <>
            <BOSsList />
            <RoomsList />
        </>
    );
};

export default AssetsConfiguration;
