import Login from "./pages/login/login";
import Home from "./pages/home/home";
import Navbar from "./components/NavBar/Navbar";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import BuildingList from "./pages/building/buildingList"
import Building from "./pages/building/building"
import AddBuilding from "./pages/building/addbuilding"
import Apartment from "./pages/apartment/apartment"
import ApartmentList from "./pages/apartment/apartmentList"
import AddApartment from "./pages/apartment/addapartment"
import RoomList from "./pages/room/roomList"
import Room from "./pages/room/room"
import SensorList from "./pages/sensor/sensorList"
import AddSensor from "./pages/sensor/addsensor"
import Sensor from "./pages/sensor/sensor"
import buildingObjectStructureList from "./pages/buildingObjectStructure/buildingObjectStructureList"
import AddBuildingObjectTructure from "./pages/buildingObjectStructure/addbuildingObjectStructure"
import buildingObjectStructure from "./pages/buildingObjectStructure/buildingObjectStructure"
import AssetsConfiguration from "./pages/assetsConfiguration/assestsConfiguration";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Switch>
          <Route exact path={"/"} component={Login}></Route>
          <Route exact path={"/home"} component={Home}></Route>
          <Route exact path={"/admin-home"} component={Home}></Route>

          <Route exact path={"/buildings"} component={BuildingList}></Route>
          <Route exact path={"/buildings/add-building"} component={AddBuilding}></Route>
          <Route path={"/buildings/building/:id"} component={Building}></Route>

          <Route exact path={"/apartments"} component={ApartmentList}></Route>
          <Route exact path={"/apartments/add-apartment"} component={AddApartment}></Route>
          <Route path={"/apartments/apartment/:id"} component={Apartment}></Route>

          <Route exact path={"/rooms"} component={RoomList}></Route>
          <Route path={"/assets-configurations/room/:id"} component={Room}></Route>

          <Route exact path={"/building-object-structures"} component={buildingObjectStructureList}></Route>
          <Route exact path={"/assets-configurations/add-building-object-structure"} component={AddBuildingObjectTructure}></Route>
          <Route path={"/assets-configurations/buildingObjectStructure/:id"} component={buildingObjectStructure}></Route>

          <Route exact path={"/sensors"} component={SensorList}></Route>
          <Route exact path={"/sensors/add-sensor"} component={AddSensor}></Route>
          <Route path={"/sensors/sensor/:id"} component={Sensor}></Route>

          <Route exact path={"/assets-configurations"} component={AssetsConfiguration}></Route>
        
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
