import {
  CREATE_BUILDING,
  GET_BUILDINGS,
  UPDATE_BUILDING,
  DELETE_BUILDING,
} from "./types";

import buildingService from "../../services/buildingService";

export const createBuilding = (Street, City, State, NumberOfApartments, Number, PostalCode) => async (dispatch) => {
  try {
    const res = await buildingService.create({ Street, City, State, NumberOfApartments, Number, PostalCode });

    dispatch({
      type: CREATE_BUILDING,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);

  }
};

export const retrieveBuildings = () => async (dispatch: any) => {
  try {
    const res = await buildingService.getBuildings();
    dispatch({
      type: GET_BUILDINGS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
  }
};

export const updateBuilding = (BuildingId, data) => async (dispatch: any) => {
  try {
    const res = await buildingService.updateBuilding(BuildingId, data);

    dispatch({
      type: UPDATE_BUILDING,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
   
    return Promise.reject(err);
  }
};

export const deleteBuilding = (BuildingId) => async (dispatch: any) => {
  try {
    await buildingService.removeBuilding(BuildingId);
    dispatch({
      type: DELETE_BUILDING,
      payload: { BuildingId },
    });
  } catch (err) {
    console.log(err);
  }
};

export const findBuildingsByAddress = (Address,Start,Length) => async (dispatch: any) => {
  try {
    const res = await buildingService.findByAddress(Address,Start,Length);

    dispatch({
      type: GET_BUILDINGS,
      payload: res.data,
    });
 
  } catch (err) {
    console.log(err);
  }
};


