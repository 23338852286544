
import { useState } from "react";
import { createRoom } from "../../store/actions/roomAction";

import { useDispatch, useSelector } from "react-redux";
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../store/actions/types';
import { roomSchema } from "../../Validations/roomValidation";
import { useFormik } from "formik";

//components
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Label from "../../components/Label/Label";

const AddRoom = () => {
  const [submitted, setSubmitted] = useState(false);
  const { message } = useSelector((state: any) => state.MessageReducer);

  const dispatch = useDispatch<any>();

  const { handleSubmit, handleChange, values, touched, errors, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        RoomId: null,
        Name: ""
      },

      validationSchema: roomSchema,

      onSubmit: ({ Name }) => {
        dispatch(createRoom(Name))
          .then(data => {
            setSubmitted(true);
            dispatch({
              type: CLEAR_MESSAGE,
            });
            window.location.replace(window.location.href)
          })
          .catch(e => {
            console.log(e);
            if (e.response.status == 401) {
              dispatch({
                type: SET_MESSAGE,
                payload: "You are not authorized to do such action",
              });
            }
          });
      }
    });

  const newRoom = () => {
    setFieldValue("Name", "")
    setSubmitted(false);
    window.location.replace(window.location.href)
  };

  return (
    <>
        <div className="submit-form room">
          <div className="page-title">Add a Room</div>
          {submitted ? (
            <div>
              <div> <h4>Room has been added successfully!</h4></div>
              <Button OnClickEvent={newRoom}>
                Add Another
              </Button>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-input">
                <label htmlFor="Name">Name</label>
                <Input
                  type="text"
                  id="Name"
                  value={values.Name}
                  OnChangeHandler={handleChange}
                  name="Name"
                  onBlur={handleBlur}
                />
                {touched.Name && errors.Name ? (
                  <div className="error-message">
                    {errors.Name}
                  </div>
                ) : null}
              </div>
              <Button type="submit">
                Submit
              </Button>
              <div><Label>{message}</Label></div>
            </form>
          )}
        </div>
 
    </>
  );
};

export default AddRoom;



