import {ASSETS_API} from "../global";

const getBuildingObjectStructures = () => {
  return ASSETS_API.get(`BuildingObjectStructure/V2/GetBuildingObjectStructures?buildingObjectStructureQueryParameters.isSearchwithpagination=false`);
};

const getBuildingObjectStructure = BuildingObjectStructureId => {
  return ASSETS_API.get(`BuildingObjectStructure/V2/GetBuildingObjectStructure/${BuildingObjectStructureId}`);
};

const updateBuildingObjectStructure = (BuildingObjectStructureId, data) => {
    return ASSETS_API.put(`BuildingObjectStructure/V2/EditBuildingObjectStructure/${BuildingObjectStructureId}`, data);
};

const createBuildingObjectStructure = data => {
  return ASSETS_API.post("BuildingObjectStructure/V2/PostBuildingObjectStructure", data);
};

const removeBuildingObjectStructure = BuildingObjectStructureId => {
  return ASSETS_API.delete(`BuildingObjectStructure/V2/DeleteBuildingObjectStructure/${BuildingObjectStructureId}`);
};

const findByname = (Name,Start,Length) => {
  return ASSETS_API.get(`BuildingObjectStructure/V2/GetBuildingObjectStructures?buildingObjectStructureQueryParameters.name=${Name}&buildingObjectStructureQueryParameters.length=${Length}&buildingObjectStructureQueryParameters.start=${Start}&buildingObjectStructureQueryParameters.isSearchwithpagination=true`);
};

export default {
  getBuildingObjectStructures,
  getBuildingObjectStructure,
  updateBuildingObjectStructure,
  createBuildingObjectStructure,
  removeBuildingObjectStructure,
  findByname
};
