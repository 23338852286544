import { useState, useEffect } from "react";
import { createBOS } from "../../store/actions/buildingObjectStructureAction";
import { useDispatch, useSelector } from "react-redux";
import { buildingObjectStructureSchema } from "../../Validations/buildingObjectStructureValidation";
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../store/actions/types'
import { Formik, Form, ErrorMessage } from "formik";
import { retrieveRooms } from "../../store/actions/roomAction";
import { useHistory } from "react-router";
import AddRoom from "../room/addroom";

//components
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Label from "../../components/Label/Label";
import DropDown from "../../components/DropDown/DropDown";

//material ui
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from "@material-ui/core";

const AddStructure = () => {
  const [submitted, setSubmitted] = useState(false);
  const { message } = useSelector((state: any) => state.MessageReducer);
  const rooms = useSelector((state: any) => state.roomReducer);
  const [open, setOpen] = useState(false)
  const [numberofRooms, setNumberofRooms] = useState(0);

  const dispatch = useDispatch<any>();

  const menu = useSelector((state: any) => state.menuReducer);
  const isOpenMobileMenu = menu;

  useEffect(() => {
    dispatch(retrieveRooms());
  }, [dispatch]);

  const newStructure = () => {
    setSubmitted(false);
  };

  //get room options
  let options = rooms.map(function (item) {
    return { value: item.RoomId, label: item.Name };
  })

  let history = useHistory();
  const goBack = () => {
    history.goBack()
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className={"main-container " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
        <div className="submit-form">
          <KeyboardBackspaceIcon className="hover-icon" fontSize="large" onClick={goBack}></KeyboardBackspaceIcon>
          <div className="page-title">Add a Building Object Structure</div>
          {submitted ? (
            <div>
              <div> <h4>Structure has been added successfully!</h4></div>
              <Button type="reset" OnClickEvent={newStructure}>
                Add Another
              </Button>
            </div>
          ) : (
            <Formik
              initialValues={{ Name: localStorage.getItem("roomName"), NumOfRooms: numberofRooms, SelectedRoomsIds: [] }}
              onSubmit={(values) => {
                const selected_rooms = []
                values.SelectedRoomsIds.forEach((option) => {
                  selected_rooms.push(option.value);
                });
                localStorage.removeItem("roomName");
                localStorage.removeItem("selectedRooms");
                dispatch(createBOS(values.Name, numberofRooms, selected_rooms))
                  .then(data => {
                    if (data.status !== 200) {
                      dispatch({
                        type: SET_MESSAGE,
                        payload: data.message,
                      });
                    }
                    else {
                      setSubmitted(true);
                      dispatch({
                        type: CLEAR_MESSAGE,
                      });
                    }
                  })
                  .catch(e => {
                    console.log(e);
                    if (e.response.status == 401) {
                      dispatch({
                        type: SET_MESSAGE,
                        payload: "You are not authorized to do such action",
                      });
                    }
                  });

              }}
              validationSchema={buildingObjectStructureSchema}
              enableReinitialize={true}
            >
              {(formProps) => (
                <Form>
                  <div className="form-input">
                    <label htmlFor="street">Name</label>
                    <Input
                      type="text"
                      id="Name"
                      OnChangeHandler={(event) => {
                      formProps.setFieldValue("Name", event.target.value)
                      localStorage.setItem("roomName", event.target.value);
                   
                      }}
                      name="Name"
                      value={localStorage.getItem("roomName")}
                    />
                    <div className="error-message"><ErrorMessage name="Name" /></div>
                  </div>
                  <div>
                    <Input
                      type="hidden"
                      id="NumOfRooms"
                      value={numberofRooms}
                      name="NumOfRooms"
                    />
                  </div>
                  <div className="form-input">
                    <label>Rooms</label>
                    <AddCircleOutlineIcon className="hover-icon add-room" fontSize="small" onClick={() => handleClickOpen()} ></AddCircleOutlineIcon>
                    <span className="add-room">(Add a room)</span>
                    <div className="form-input-inner">
                      <DropDown
                        items={options}
                        onChange={(option) => {
                          setNumberofRooms(option.length);
                          formProps.setFieldValue("SelectedRoomsIds", option);
                          formProps.setFieldValue("NumOfRooms", option.length)
                          localStorage.setItem("selectedRooms",JSON.stringify(option));
                        }}
                        name="SelectedRoomsIds"
                        isMulti={true}
                        selectedItem={JSON.parse(localStorage.getItem("selectedRooms"))}
                      />
                    </div>
                    <div className="error-message"><ErrorMessage name="NumOfRooms" /></div>
                  </div>
                  <Button type="submit">
                    Submit
                  </Button>
                  <div><Label>{message}</Label></div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <IconButton aria-label="close" className="float-right" onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
            <AddRoom></AddRoom>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default AddStructure;



