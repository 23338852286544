import axios from "axios";

export const ASSETS_API = axios.create({
  baseURL: process.env.REACT_APP_ASSETS_API_DOMAIN,
  headers: {
    'Content-Type': 'application/json',
    "Authorization": 'bearer ' + localStorage.getItem('token'),
  },
})

export const SVG_WEB_API=process.env.REACT_APP_SVG_WEB_API 
export const SAVE_APARTMENT="Apartment/V2/PostApartment/"
export const UPDATE_APARTMENT="Apartment/V2/EditApartment/" 
