import * as yup from 'yup'

export const apartmentSchema = yup.object().shape({
    BuildingId: yup.number().required('Please select a building')
    .typeError('Please select a building'),
    StructureId: yup.number(),
    IsOccupied: yup.boolean(),
    Floor: yup.string().required("Floor is required"),
    Number: yup.string().required("Object Number is required"),
    Size: yup.number()
        .typeError('Please enter a valid number'),
    Map: yup.string()
})

