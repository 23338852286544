import { useState } from "react";
import { createBuilding } from "../../store/actions/buildingAction";
import { useDispatch, useSelector } from "react-redux";
import { buildingSchema } from "../../Validations/buildingValidation";
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../store/actions/types'
import { useFormik } from "formik";
import { useHistory } from "react-router";

//components
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Label from "../../components/Label/Label";

//material ui
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const AddBuilding = () => {
  const [submitted, setSubmitted] = useState(false);
  const { message } = useSelector((state: any) => state.MessageReducer);

  const dispatch = useDispatch<any>();

  const menu = useSelector((state: any) => state.menuReducer);
  const isOpenMobileMenu = menu;

  const { handleSubmit, handleChange, values, touched, errors, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        BuildingId: null,
        City: "",
        Street: "",
        State: "",
        NumberOfApartments: "",
        Number: "",
        PostalCode: "",
      },

      validationSchema: buildingSchema,
     
      onSubmit: ({ Street, City, State, NumberOfApartments, Number, PostalCode }) => {
        dispatch(createBuilding(Street, City, State, NumberOfApartments, Number, PostalCode))
          .then(data => {
            if (data.status === 400) {
              dispatch({
                type: SET_MESSAGE,
                payload: "This address does not exist!",
              });
            }
            else if (data.status !== 200) {
              dispatch({
                type: SET_MESSAGE,
                payload: "An error occurred!",
              });
            }
            else {
              setSubmitted(true);
              dispatch({
                type: CLEAR_MESSAGE,
              });
            }
          })
          .catch(e => {
            console.log(e);
            if (e.response) {
              dispatch({
                type: SET_MESSAGE,
                payload: "This address does not exist!",
              });
            }
            if (e.response.status == 401) {
              dispatch({
                type: SET_MESSAGE,
                payload: "You are not authorized to do such action",
              });
            }
          });
      }
    });

  const newBuilding = () => {
    setSubmitted(false);
    setFieldValue("Street", "")
    setFieldValue("City", "")
    setFieldValue("State", "")
    setFieldValue("Number", "")
    setFieldValue("PostalCode", "")
    setFieldValue("NumberOfApartments", "")
  };

  let history = useHistory();
  const goBack = () => {
    history.goBack()
  };

  return (
    <>
      <div className={"main-container " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
        <div className="submit-form">
        <KeyboardBackspaceIcon className="hover-icon" fontSize="large" onClick={goBack}></KeyboardBackspaceIcon>
          <div className="page-title">Add a Building</div>
          {submitted ? (
            <div>
              <div> <h4>Building has been added successfully!</h4></div>
              <Button type="reset" OnClickEvent={newBuilding}>
                Add Another
              </Button>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-input">
                <label htmlFor="Street">Street</label>
                <Input
                  type="text"
                  id="Street"
                  value={values.Street}
                  OnChangeHandler={handleChange}
                  name="Street"
                  onBlur={handleBlur}
                />
                {touched.Street && errors.Street ? (
                  <div className="error-message">
                    {errors.Street}
                  </div>
                ) : null}
              </div>
              <div className="form-input">
                <label htmlFor="City">City</label>
                <Input
                  type="text"
                  id="City"
                  value={values.City}
                  OnChangeHandler={handleChange}
                  name="City"
                  onBlur={handleBlur}
                />
                {touched.City && errors.City ? (
                  <div className="error-message">
                    {errors.City}
                  </div>
                ) : null}
              </div>
              <div className="form-input">
                <label htmlFor="State">State</label>
                <Input
                  type="text"
                  id="State"
                  value={values.State}
                  OnChangeHandler={handleChange}
                  name="State"
                  onBlur={handleBlur}
                />
                {touched.State && errors.State ? (
                  <div className="error-message">
                    {errors.State}
                  </div>
                ) : null}
              </div>
              <div className="form-input">
                <label htmlFor="Number">Number</label>
                <Input
                  type="text"
                  id="Number"
                  value={values.Number}
                  OnChangeHandler={handleChange}
                  name="Number"
                  onBlur={handleBlur}
                />
                {touched.Number && errors.Number ? (
                  <div className="error-message">
                    {errors.Number}
                  </div>
                ) : null}
              </div>
              <div className="form-input">
                <label htmlFor="PostalCode">Postal Code</label>
                <Input
                  type="text"
                  id="PostalCode"
                  value={values.PostalCode}
                  OnChangeHandler={handleChange}
                  name="PostalCode"
                  onBlur={handleBlur}
                />
                {touched.PostalCode && errors.PostalCode ? (
                  <div className="error-message">
                    {errors.PostalCode}
                  </div>
                ) : null}
              </div>
              <div className="form-input">
                <label htmlFor="NumberOfApartments">Number Of Apartments</label>
                <Input
                  type="text"
                  id="NumberOfApartments"
                  value={values.NumberOfApartments}
                  OnChangeHandler={handleChange}
                  name="NumberOfApartments"
                  onBlur={handleBlur}
                />
                {touched.NumberOfApartments && errors.NumberOfApartments ? (
                  <div className="error-message">
                    {errors.NumberOfApartments}
                  </div>
                ) : null}
              </div>
              <Button type="submit">
                Submit
              </Button>
              <div><Label>{message}</Label></div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default AddBuilding;



