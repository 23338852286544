import {
    TOGGLE_MENU,
} from "../actions/types";

const initialState = {
    isMobileMenuvisible: false
}

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_MENU:
            return { ...state,
                 isMobileMenuvisible: !state.isMobileMenuvisible }

        default:
            return state;
    }
};

export default menuReducer;