import { useState, useEffect } from "react";
import buildingService from "../../services/buildingService";
import { updateBuilding } from '../../store/actions/buildingAction'
import { useDispatch, useSelector } from "react-redux";
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../store/actions/types'
import { useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";

//components
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

//material ui
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { buildingSchema } from "../../Validations/buildingValidation";

export interface BuildingProps { }

const Building = (props) => {
  const initialBuildingState = {
    BuildingId: null,
    Street: "",
    Number: 0,
    City: "",
    State: "",
    NumberOfApartments: 0,
    PostalCode: "",
    Apartments: []
  };

  const [currentBuilding, setCurrentBuilding] = useState(initialBuildingState);
  const { message } = useSelector((state: any) => state.MessageReducer);

  const dispatch = useDispatch<any>();

  const menu = useSelector((state: any) => state.menuReducer);
  const isOpenMobileMenu = menu;

  const getBuilding = id => {
    buildingService.getBuilding(id)
      .then(response => {
        setCurrentBuilding(response.data);
        dispatch({
          type: CLEAR_MESSAGE
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getBuilding(props.match.params.id);
  }, [props.match.params.id]);

  const handleInputChange = event => {
    const { name, value } = event.target;
   setCurrentBuilding({ ...currentBuilding, [name]: value });
  };

  let history = useHistory();
  const goBack = () => {
    history.goBack()
  };

  return (
    <>
      <div className={"main-container " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
        <div className="submit-form">
          <KeyboardBackspaceIcon className="hover-icon" fontSize="large" onClick={goBack}></KeyboardBackspaceIcon>
          <div className="page-title">Edit Building</div>
          <Formik
            initialValues=  {currentBuilding}
            onSubmit={(values) => {
              dispatch(updateBuilding(currentBuilding.BuildingId, currentBuilding))
              .then(response => {
                if (response.status === 400) {
                  dispatch({
                    type: SET_MESSAGE,
                    payload: "This address does not exist!",
                  });
                }
                else if (response.status != 200) {
                  dispatch({
                    type: SET_MESSAGE,
                    payload: "An error occurred!",
                  });
                }
                else {
                  dispatch({
                    type: SET_MESSAGE,
                    payload: "The building was updated successfully!",
                  });
                }
              })
              .catch(e => {
                console.log(e);
                dispatch({
                  type: SET_MESSAGE,
                  payload: "This address does not exist!",
                });
                if (e.response.status == 401) {
                  dispatch({
                    type: SET_MESSAGE,
                    payload: "You are not authorized to do such action",
                  });
                }
              });
            }}
            validationSchema={buildingSchema}
            enableReinitialize={true}
          >
            {(formProps) => {
              const { values} = formProps;
              return (
              <Form>
                <div className="form-input">
                  <label htmlFor="Street">Street</label>
                  <Input
                    type="text"
                    id="Street"
                    name="Street"
                    value={values.Street}
                    OnChangeHandler={handleInputChange}
                  />
                  <div className="error-message"><ErrorMessage name="Street" /></div>
                </div>
         
                <div className="form-input">
                  <label htmlFor="City">City</label>
                  <Input
                    type="text"
                    id="City"
                    name="City"
                    value={values.City}
                    OnChangeHandler={handleInputChange}
                  />
                    <div className="error-message"><ErrorMessage name="City" /></div>
                </div>

                <div className="form-input">
                  <label htmlFor="State">State</label>
                  <Input
                    type="text"
                    id="State"
                    name="State"
                    value={values.State}
                    OnChangeHandler={handleInputChange}
                  />
                  <div className="error-message"><ErrorMessage name="State" /></div>
                </div>

                <div className="form-input">
                  <label htmlFor="PostalCode">Postal Code</label>
                  <Input
                    type="text"
                    id="PostalCode"
                    name="PostalCode"
                    value={values.PostalCode}
                    OnChangeHandler={handleInputChange}
                  />
                </div>

                <div className="form-input">
                  <label htmlFor="NumberOfApartments">NumberOfApartments</label>
                  <Input
                    type="text"
                    id="NumberOfApartments"
                    name="NumberOfApartments"
                    value={values.NumberOfApartments}
                    OnChangeHandler={handleInputChange}
                  />
                  <div className="error-message"><ErrorMessage name="NumberOfApartments" /></div>
                </div>

                <div className="form-input">
                  <label htmlFor="Number">Number</label>
                  <Input
                    type="text"
                    id="Number"
                    name="Number"
                    value={values.Number}
                    OnChangeHandler={handleInputChange}
                  />
                  <div className="error-message"><ErrorMessage name="Number" /></div>
                </div>
                <Button
                 type="submit">
                  Update
                </Button>
              </Form> );
            }}
          </Formik>
          <p>{message}</p>
        </div>
      </div>
    </>
  );
};

export default Building;