import "./Upload.css";

export interface UploadProps {
  OnChangeHandler?: any;

}

const Upload: React.FC<UploadProps> = ({
  OnChangeHandler,

}) => {
  return (
    <input type="file" name="file" className="upload-style" onChange={OnChangeHandler} />
  );
};

export default Upload;
