export interface CheckBoxProps {
    checked?: any;
    onChange?: any;
    value?: any,
    name?: any

}

const CheckBox: React.FC<CheckBoxProps> = ({
    checked,
    onChange,
    name
}) => {

    return (
        <input type="checkbox"
            checked={checked}
            onChange={onChange}
        />
    );
};

export default CheckBox;
