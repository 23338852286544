import "./building.css";
import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import buildingService from "../../services/buildingService";
import { useDispatch, useSelector } from "react-redux";
import { findBuildingsByAddress, deleteBuilding } from "../../store/actions/buildingAction";
import { CLEAR_MESSAGE } from "../../store/actions/types";
import { retrieveUserInfo } from "../../store/actions/authAction";

//material ui
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import Pagination from '@material-ui/lab/Pagination';

// Components:
import Label from "../../components/Label/Label";
import Input from "../../components/Input/Input";
import DropDown from "../../components/DropDown/DropDown";
import Button from "../../components/Button/Button";

export interface BuildingListProps { }
const BuildingsList: React.FC<BuildingListProps> = () => {

  const buildings = useSelector((state: any) => state.buildingReducer);
  const [currentBuilding, setCurrentBuilding] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");
  const [clickedBuilding, setClickedBuilding] = useState("");
  const [active, setActive] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [hanldeDelete, isHanldeDelete] = useState("");

  const dispatch = useDispatch<any>();

  const menu = useSelector((state: any) => state.menuReducer);
  const user = useSelector((state: any) => state.LoginReducer);
  const isOpenMobileMenu = menu;

  useEffect(() => {
    dispatch(findBuildingsByAddress(searchTitle, 0, pageSize));
    dispatch(retrieveUserInfo());
  }, [dispatch]);

  const onChangeSearchTitle = e => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
    refreshData();
    dispatch(findBuildingsByAddress(searchTitle, 0, pageSize));
  };

  const refreshData = () => {
    dispatch(findBuildingsByAddress(searchTitle, 0, pageSize));
    setCurrentBuilding(null);
  };

  const gotoBuildingInfo = (building, index) => {
    buildingService.getBuilding(building.BuildingId)
      .then(response => {
        setCurrentBuilding(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    var building_details = document.getElementsByClassName("building-details")[index].id;
    setActive(true);
    setClickedBuilding(building_details)
  }

  const handleClickOpen = (sensor) => {
    setOpen(true);
    isHanldeDelete(sensor)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    dispatch(deleteBuilding(hanldeDelete));
  };

  const handlePageChange = (e, value) => {
    dispatch(findBuildingsByAddress(searchTitle, value * pageSize - pageSize, pageSize));
    setActive(false);
  };

  return (
    <>
      {(user.UserRole == 'Admin') ?
        <div className={"wrapper list " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
          <div className="page-title"><h1>Buildings List</h1></div>
          <div className="create-table full-width list-items building">
            <div className="create-row">
              <div className="create-cell">
                <div className="float-left full-width">
                  <div className="float-left full-width search-box">
                    <Input
                      type="text"
                      placeholder="Search by address"
                      value={searchTitle}
                      OnChangeHandler={onChangeSearchTitle}
                    />
                  </div>
                  <div className="float-left full-width">
                    <span className="float-left page-size-text">Page Size:</span>
                    <div className="float-left page-size-filter">
                      <DropDown
                        items={[{ value: '10', label: '10' },
                        { value: '25', label: '25' },
                        { value: '50', label: '50' },
                        { value: '100', label: '100' }]}
                        onChange={(e) => {
                          setPageSize(e.value)
                          dispatch(findBuildingsByAddress(searchTitle, 0, e.value));
                          setActive(false);
                        }
                        }
                        defaultOption={{ label: "10", value: 10 }}
                      />
                    </div>
                  </div>
                  <div className="float-right add-icon">
                    <NavLink to={{ pathname: "buildings/add-building" }} activeClassName="active-menu">
                      <AddCircleOutlineIcon className="hover-icon" fontSize="large" onClick={() =>
                        dispatch({
                          type: CLEAR_MESSAGE
                        })}></AddCircleOutlineIcon>
                    </NavLink>
                  </div>
                </div>
                <div className="scroll-list building">
                  <div className="float-left full-width">
                    <div className="float-left list-item title ID"> ID#</div>
                    <div className="float-left list-item title address"> Address</div>
                    <div className="float-left list-item title">City</div>
                    <div className="float-left list-item title">Number Of Apartments</div>
                    <div className="float-left list-item title">BuiltOn</div>
                  </div>
                  {buildings && buildings.length > 0 &&
                    buildings.map((building, index) => (
                      <div className={"float-left full-width list-row " + (clickedBuilding == index && active ? 'active' : "")} key={index} >
                        <div className="float-left list-item ID"> {building.BuildingId}</div>
                        <div className="float-left list-item address"> {building.Address}</div>
                        <div className="float-left list-item"> {building.City}</div>
                        <div className="float-left list-item"> {building.NumberOfApartments}</div>
                        <div className="float-left list-item date">  {building.BuiltOn}</div>
                        <div className="float-right info-icon building-details" id={index}> <InfoIcon className="hover-icon" onClick={() => gotoBuildingInfo(building, index)}></InfoIcon></div>
                        <div className="float-right info-icon"> <DeleteIcon className="hover-icon" onClick={() => handleClickOpen(building.BuildingId)}></DeleteIcon></div>
                        <div className="float-right info-icon">
                          <NavLink to={{ pathname: "buildings/building/" + building.BuildingId }} activeClassName="active-menu">
                            <EditIcon className="hover-icon" />
                          </NavLink>
                        </div>
                        <div id={index} className={"details-block float-left full-width sensor " + (clickedBuilding == index && active ? 'active' : "")} >
                          <div className="details-inner">
                            {currentBuilding ? (
                              <div>
                                <div className="details-title"> <h4>Building Details</h4></div>
                                <div>
                                  <Label>
                                    <strong>Street:</strong>
                                  </Label>
                                  {currentBuilding.Street}
                                </div>
                                <div>
                                  <Label>
                                    <strong>City:</strong>
                                  </Label>
                                  {currentBuilding.City}
                                </div>
                                <div>
                                  <Label>
                                    <strong>Number of Apartments:</strong>
                                  </Label>
                                  {currentBuilding.NumberOfApartments}
                                </div>
                                <div>
                                  <Label>
                                    <strong>Built On:</strong>
                                  </Label>
                                  {currentBuilding.BuiltOn}
                                </div>
                                <div>
                                  <Label>
                                    <strong>Number:</strong>
                                  </Label>
                                  {currentBuilding.Number}
                                </div>
                                <div>
                                  <Label>
                                    <strong>Postal Code:</strong>
                                  </Label>
                                  {currentBuilding.PostalCode}
                                </div>
                                {currentBuilding.Apartments && currentBuilding.Apartments.length > 0 && (
                                  <div>
                                    <div className="related-title"> <h3>Related Apartments</h3></div>
                                    <div className="create-table full-width list-items related-apartments">
                                      <div className="create-row">
                                        <div className="create-cell">
                                          <div className="float-left full-width">
                                            <div className="float-left list-item title ID">ID#</div>
                                            <div className="float-left list-item title"> Floor Number</div>
                                            <div className="float-left list-item title"> Object Number</div>
                                            <div className="float-left list-item title"> Occupied</div>
                                          </div>
                                          {(currentBuilding.Apartments || []).map((apartment, index) => (
                                            <div className="float-left full-width list-row" key={index}>
                                              <div className="float-left list-item ID">{apartment.ApartmentId}</div>
                                              <div className="float-left list-item">{apartment.Floor}</div>
                                              <div className="float-left list-item">{apartment.Number}</div>
                                              <div className="float-left list-item">{apartment.IsOccupied ? 'yes' : 'no'}</div>
                                              <div className="float-right info-icon">
                                                <NavLink to={"/apartments/apartment/" + apartment.ApartmentId} activeClassName="active-menu">
                                                  <EditIcon className="hover-icon" />
                                                </NavLink>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                    <a href="/apartments">Manage All Apartments</a>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="list-end "></div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div>
                  <div className="float-right pagination-top">
                    {(Array.isArray(buildings) && buildings.length) ? <Pagination size="small" count={Math.ceil(buildings[0].TotalCount / pageSize)} onChange={handlePageChange} /> : ""}
                  </div>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
                    <DialogContent>
                    </DialogContent>
                    <DialogActions>
                      <Button OnClickEvent={() => handleClose()} >
                        No
                      </Button>
                      <Button OnClickEvent={() => handleConfirm()} >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div> : <div className="wrapper warning-text">You are not authorized to access this page</div>}
    </>
  );
};

export default BuildingsList;
