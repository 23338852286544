
import "./room.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import roomService from "../../services/roomService";
import { useDispatch, useSelector } from "react-redux";
import { retrieveRooms, findRoomsByName, deleteRoom } from "../../store/actions/roomAction";
import AddRoom from "./addroom";

//material ui
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';

// Components:
import Label from "../../components/Label/Label";
import Input from "../../components/Input/Input";
import DropDown from "../../components/DropDown/DropDown";
import Button from "../../components/Button/Button";


export interface RoomListProps { }
const RoomsList: React.FC<RoomListProps> = () => {

  const rooms = useSelector((state: any) => state.roomReducer);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");
  const [clickedRoom, setClickedRoom] = useState("");
  const [active, setActive] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [hanldeDelete, isHanldeDelete] = useState("");
  const [openAddRoom, setOpenAddRoom] = useState(false);
  const menu = useSelector((state: any) => state.menuReducer);

  const isOpenMobileMenu = menu;

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(findRoomsByName(searchTitle, 0, pageSize));
  }, [dispatch]);

  const onChangeSearchTitle = e => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
    refreshData();
    dispatch(findRoomsByName(searchTitle, 0, pageSize));
  };

  const refreshData = () => {
    retrieveRooms();
    setCurrentRoom(null);
  };

  const gotoRoomInfo = (room, index) => {
    roomService.getRoom(room.RoomId)
      .then(response => {
        setCurrentRoom(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    var room_details = document.getElementsByClassName("room-details")[index].id;
    setActive(true);
    setClickedRoom(room_details);
  }

  const handleClickOpen = (sensor) => {
    setOpen(true);
    isHanldeDelete(sensor)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    dispatch(deleteRoom(hanldeDelete));
  };

  const handlePageChange = (e, value) => {
    dispatch(findRoomsByName(searchTitle, value * pageSize - pageSize, pageSize));
    setActive(false);
  };

  const handleAddRoom = () => {
    setOpenAddRoom(true);
  };

  const handleRoomClose = () => {
    setOpenAddRoom(false);
  };
  return (
    <>
      <div className={"wrapper list " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
        <div className="page-title"><h1>Rooms List</h1></div>
        <div className="create-table full-width list-items room">
          <div className="create-row">
            <div className="create-cell">
              <div className="float-left full-width">
                <div className="float-left search-box">
                  <Input
                    type="text"
                    placeholder="Search by name"
                    value={searchTitle}
                    OnChangeHandler={onChangeSearchTitle}
                  />
                </div>
                <div className="float-left full-width">
                  <span className="float-left page-size-text">Page Size:</span>
                  <div className="float-left page-size-filter">
                    <DropDown
                      items={[{ value: '10', label: '10' },
                      { value: '25', label: '25' },
                      { value: '50', label: '50' },
                      { value: '100', label: '100' }]}
                      onChange={(e) => {
                        setPageSize(e.value)
                        dispatch(findRoomsByName(searchTitle, 0, e.value));
                        setActive(false);
                      }
                      }
                      defaultOption={{ label: "10", value: 10 }}
                    />
                  </div>
                </div>
                <div className="float-right add-icon">
                    <AddCircleOutlineIcon className="hover-icon" fontSize="large" onClick={() => handleAddRoom()}></AddCircleOutlineIcon>
                </div>
              </div>
              <div className="scroll-list structure">
                <div className="float-left full-width">
                  <div className="float-left list-item title ID"> ID#</div>
                  <div className="float-left list-item title address"> Name</div>
                </div>
                {rooms && rooms.length > 0 &&
                  rooms.map((room, index) => (
                    <div className={"float-left full-width list-row " + (clickedRoom == index && active ? 'active' : "")} key={index} >
                      <div className="float-left list-item ID"> {room.RoomId}</div>
                      <div className="float-left list-item address"> {room.Name}</div>
                      <div className="float-right info-icon room-details" id={index}> <InfoIcon className="hover-icon" onClick={() => gotoRoomInfo(room, index)}></InfoIcon></div>
                      <div className="float-right info-icon"> <DeleteIcon className="hover-icon" onClick={() => handleClickOpen(room.RoomId)}></DeleteIcon></div>
                      <div className="float-right info-icon">
                        <Link to={"/assets-configurations/room/" + room.RoomId}>
                          <EditIcon className="hover-icon" />
                        </Link>
                      </div>
                      <div id={index} className={"details-block float-left full-width " + (clickedRoom == index && active ? 'active' : "")} >
                        <div className="details-inner">
                          {currentRoom ? (
                            <div>
                              <div className="details-title"> <h4>Room Details</h4></div>
                              <div>
                                <Label>
                                  <strong>Name:</strong>
                                </Label>
                                {currentRoom.Name}
                              </div>

                              {currentRoom.Sensors && currentRoom.Sensors.length > 0 && (
                                <div>
                                  <div className="details-title"> <h3>Related Sensors</h3></div>
                                  <div className="create-table full-width list-items related-apartments">
                                    <div className="create-row">
                                      <div className="create-cell">
                                        <div className="float-left full-width">
                                          <div className="float-left list-item title address"> Serial Number</div>
                                          <div className="float-left list-item title address"> Category</div>
                                          <div className="float-left list-item title address"> Manufacturer</div>
                                          <div className="float-left list-item title address"> Sensor Name/Designation</div>
                                        </div>
                                        {(currentRoom.Sensors || []).map((sensor, index) => (
                                          <div className="float-left full-width list-row" key={index}>
                                            <div className="float-left list-item">{sensor.SerialNumber}</div>
                                            <div className="float-left list-item">{sensor.CategoryName}</div>
                                            <div className="float-left list-item">{sensor.Manufacturer}</div>
                                            <div className="float-left list-item">{sensor.TechnicalDisplayName}</div>
                                            <div className="float-right info-icon">
                                              <Link to={"sensors/sensor/" + sensor.SensorId}>
                                                <EditIcon className="hover-icon" />
                                              </Link>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  <a href="/sensors">Manage All Sensors</a>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="list-end"></div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                <div className="float-right pagination-top">
                  {(Array.isArray(rooms) && rooms.length) ? <Pagination size="small" count={Math.ceil(rooms[0].TotalCount / pageSize)} onChange={handlePageChange} /> : ""}
                </div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
                  <DialogContent>
                  </DialogContent>
                  <DialogActions>
                    <Button OnClickEvent={() => handleClose()} >
                      No
                    </Button>
                    <Button OnClickEvent={() => handleConfirm()} >
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={openAddRoom}
          onClose={handleRoomClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <IconButton aria-label="close" className="float-right" onClick={() => handleRoomClose()}>
              <CloseIcon />
            </IconButton>
            <AddRoom></AddRoom>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default RoomsList;
