import  { useState, useEffect } from "react";
import roomService from "../../services/roomService";
import { updateRoom } from '../../store/actions/roomAction'
import { useDispatch, useSelector } from "react-redux";
import { SET_MESSAGE } from '../../store/actions/types';
import { Formik, Form, ErrorMessage } from "formik";
import { roomSchema } from "../../Validations/roomValidation";
import { useHistory } from "react-router";

//components
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

//material ui
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export interface RoomProps { }

const Room = (props) => {
    const initialRoomState = {
        RoomId: null,
        Name: "",
        Sensors: []
    };

    const [currentRoom, setCurrentRoom] = useState(initialRoomState);
    const { message } = useSelector((state: any) => state.MessageReducer);
    const menu = useSelector((state: any) => state.menuReducer);
    const isOpenMobileMenu = menu;

    const dispatch = useDispatch<any>();

    const getRoom = (id) => {
        roomService.getRoom(id)
            .then(response => {
                setCurrentRoom(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        getRoom(props.match.params.id);
    }, [props.match.params.id]);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setCurrentRoom({ ...currentRoom, [name]: value });
    };

    let history = useHistory();
    const goBack = () => {
        history.goBack()
    };

    return (
        <>
            <div className={"main-container " + (!isOpenMobileMenu.isMobileMenuvisible ? 'active' : "")}>
                {currentRoom ? (
                    <div className="submit-form">
                         <KeyboardBackspaceIcon className="hover-icon" fontSize="large" onClick={goBack}></KeyboardBackspaceIcon>
                        <div className="page-title">Edit Room</div>
                        <Formik
                            initialValues={currentRoom}
                            onSubmit={(values) => {
                                dispatch(updateRoom(currentRoom.RoomId, currentRoom))
                                    .then(response => {

                                        if (response.status != 200) {
                                            dispatch({
                                                type: SET_MESSAGE,
                                                payload: "An error occurred!",
                                            });
                                        }
                                        else {
                                            dispatch({
                                                type: SET_MESSAGE,
                                                payload: "The room was updated successfully!",
                                            });
                                        }
                                    })
                                    .catch(e => {
                                        console.log(e);
                                        if (e.response.status == 401) {
                                            dispatch({
                                                type: SET_MESSAGE,
                                                payload: "You are not authorized to do such action",
                                            });
                                        }
                                    });

                            }}
                            validationSchema={roomSchema}
                            enableReinitialize={true}
                        >
                            {(formProps) => {
                                const { values } = formProps;
                                return (
                                    <Form>
                                        <div className="form-input">
                                            <label htmlFor="Name">Name</label>
                                            <Input
                                                type="text"
                                                id="Name"
                                                name="Name"
                                                value={currentRoom.Name}
                                                OnChangeHandler={handleInputChange}
                                            />
                                            <div className="error-message"><ErrorMessage name="Name" /></div>
                                        </div>
                                        <Button
                                            type="submit">
                                            Update
                                        </Button>
                                    </Form>);
                            }}
                        </Formik>
                        <p>{message}</p>
                    </div>
                ) : (
                    <div>
                        <br />
                    </div>
                )}
            </div>
        </>
    );
};

export default Room;
